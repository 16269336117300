import React, { Component } from 'react'

import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

export default class inputTwo extends Component {
  render () {
    return (
      <Grid
        item
        xs={this.props.xs}
        sm={this.props.sm}
        lg={this.props.lg}
        style={{ paddingLeft: 5, paddingRight: 5 }}
      >
        <InputMask
          mask={this.props.mask}
          id={this.props.id}
          name={this.props.name}
          maskPlaceholder={this.props.maskPlaceholder}
          value={this.props.value}
          onChange={this.props.onChange}
        >
          <TextField
            // multiline={this.props.multiline}
            // minRows={this.props.minRows}
            error={this.props.error}
            variant={this.props.variant}
            color={this.props.color}
            margin={this.props.margin}
            fullWidth
            name={this.props.name}
            label={this.props.label}
            type={this.props.type}
            id={this.props.id}
            // value={this.props.value}
            autoComplete='new-password'
            onChange={this.props.onChange}
            helperText={this.props.helperText}
            InputProps={{
              inputProps: {
                style: { fontSize: 80, textAlign: 'center' }
              }
            }}
          />
        </InputMask>
      </Grid>
    )
  }
}

inputTwo.defaultProps = {
  label: '...',
  placeholder: '...',
  helperText: '...',
  xs: 12,
  sm: 12,
  lg: 12,
  type: 'text',
  error: false,
  maskPlaceholder: '_',
  mask: null
}

inputTwo.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string
}
