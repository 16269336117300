import React, { Component } from 'react'


import Card from '@mui/material/Card';


export default class CardOne extends Component {
  render () {
    return (
      <Card sx={this.props.style}>
          { this.props.children }
      </Card>
    )
  }
}
