import React, { Component } from 'react'

import Grid from '@mui/material/Grid'

export default class CardInnerOne extends Component {
  render () {
    return (
      <Grid
        item
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.sm}
        lg={this.props.lg}
        wrap='nowrap'
        justify='center'
        align='center'
        style={{
          backgroundColor: this.props.backgroundColor,
          padding: 5,
          paddingBottom: 15
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between',minHeight:60 }}>
          <div style={{ flex: 1 }}>
            <div
              style={{ textAlign: 'left', fontWeight: '800', color: '#636363' }}
            >
              {this.props.title}
            </div>
            <div
              style={{
                textAlign: 'left',
                fontWeight: '500',
                color: 'black',
                fontSize: 12,
                marginRight: 20
              }}
            >
              {this.props.subQuite}
            </div>
          </div>
          <div style={{display:'flex', alignItems:'center',marginRight:20}}>
            BB
          </div>
        </div>

        <div
          style={{
            textAlign: 'right',
            fontWeight: '500',
            color: '#a8a8a8',
            fontSize: 40,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <div style={{ flex: 1 }}>{this.props.explanation} </div>
          <div style={{ fontSize: 14, fontWeight: '400', PADDİNG: 5 }}>
            {' '}
            {this.props.parity}{' '}
          </div>
        </div>
      </Grid>
    )
  }
}
