import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'


export const Cols = ( onRowClickedDelete) => {
  return [
    {
      name: 'Sıra',
      selector: 'order',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.order}</div>
    },

    {
      name: 'Açıklama',
      selector: 'desc',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.desc}</div>
    },
   

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.uuid)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
