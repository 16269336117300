import React, { Component } from 'react'
import MainLayout from './MainLayout'
import { FormHeader } from '../BASES'
import Grid from '@material-ui/core/Grid'

import { LottiePlayerOne } from '../../lib/lottie'
import { GridBlock } from '../../lib/grid'

import * as animationData from '../../../asserts/lotties/lf30_editor_fohvsvls.json'

export default class FormLayout extends Component {
  render () {
    return (
      <MainLayout  style={{padding: 10}} >
        <GridBlock style={{  display: this.props.loading == true ? null : 'none', justifyContent: 'center'}}>
          <LottiePlayerOne animationData={animationData}   />
        </GridBlock>


        <GridBlock style={{ display: this.props.loading == false ? null : 'none'  }}>
  
            <FormHeader
              naviName={this.props.naviName}
              naviIcon={this.props.naviIcon}
              recordIcon={this.props.recordIcon}
              recordAction={this.props.recordAction}
              updateIcon={this.props.updateIcon}
              filesIcon={this.props.filesIcon}
              activeBack={this.props.activeBack}
            />
        
        
          <Grid container>{this.props.children}</Grid>
        </GridBlock>
      </MainLayout>
    )
  }
}
