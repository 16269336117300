import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { SelectOne } from '../../../../lib/select'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'

import { getReduceState } from '../../../../funcs/BASES/getStateOfRed'
import { showMultipleMessages } from '../../../../funcs/BASES/showErrorMultiMessages'

export default class AddModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      program_id: '',
      startTime: '',
      uuid: '',
    }
  }

  submit = () => {


    if (this.state.program_id == '') {
      showMultipleMessages("Program boş bırakılamaz.", 'warning')
    } else if (this.state.startTime == '') {
      showMultipleMessages("Başlangıç tarihi boş bırakılamaz.", 'warning')
    } else {

      let date = this.state.startTime + "T00:00:00.000Z"
      let challangeId = this.props.programOptions.find(aa => aa.value == this.state.program_id)?.challangeId
      this.props.fakeThis.assign({
        "users_id": this.props.users_id,
        "program_id": this.state.program_id,
        "startTime": date,
        "challangeId": challangeId ? challangeId : null,
      })
    }


      // if (this.props.fakeState.datas.find(aa => aa.orderDay == this.state.orderDay)?.program_program_id != null) {
      //   showMultipleMessages("Eklemis oldugunuz sıra gününe bir antrenman var.Lütfen başka bir gün belirleyiniz.", 'warning')
      // } else if (this.state.program_program_id == '') {
      //   showMultipleMessages("Eklemek için mutlaka bir antrenman seçilmiş olmalıdır.", 'warning')
      // } else if (this.state.orderDay == '') {
      //   showMultipleMessages("Eklemek için mutlaka bir gün seçilmiş olmalıdır.", 'warning')
      // } else {
      //   this.setState(
      //     {
      //       uuid: uuidv4()
      //     },
      //     () => {
      //       this.props.fakeThis.setState(
      //         {
      //           datas: [...this.props.fakeState.datas, this.state],
      //           modalIsOpen: false
      //         },
      //         () => {
      //           this.setState({
      //             program_program_id: '',
      //             orderDay: '',
      //             uuid: ''
      //           })
      //         }
      //       )
      //     }
      //   )
      // }


    }

    setVal = e => {
      this.setState({ [e.target.name]: e.target.value })
    }

    render() {
      return (
        <ModalOne
          open={this.props.open}
          bgDropColor={'rgba(128, 2, 255, 0.2)'}
          fullScreen={true}
        >
          <FormLayout
            naviIcon={<FaWpforms size={30} color={'white'} />}
            recordIcon={<FaSave size={30} color={'white'} />}
            recordAction={this.submit}
            // filesIcon={<FaFolder size={30} color={'white'} />}
            activeBack={() => {
              this.props.fakeThis.setState({ modalIsOpen: false })
            }}
            loading={false}
          >
            <GridBlock
              title={getTranslatation('egzersizehareketbağlayın', 'PROGRAMS')}
              container={true}
              xs={12}
              sm={12}
              lg={12}
            >
              <SelectOne
                placeholder={getTranslatation('program_id', 'CHALLANGES')}
                label={getTranslatation('program_id', 'CHALLANGES')}
                helperText={getTranslatation('program_id', 'CHALLANGES')}
                xs={12}
                sm={12}
                lg={6}
                value={this.state.program_id}
                onChange={this.setVal}
                name='program_id'
                id='program_id'
                options={this.props.programOptions}
              />





              <InputOne
                disabled={this.state.program_id == '' ? true : false}
                type='date'
                color={'success'}
                variant='filled'
                margin='none'
                required
                fullWidth
                xs={12}
                sm={12}
                lg={6}
                id='startTime'
                label={getTranslatation('startTime', 'CHALLANGES')}
                name='startTime'
                value={this.state.startTime}
                onChange={this.setVal}
                // error={this.state.mailOrPhone_ERROR.length > 0}
                helperText={getTranslatation('startTimeHelper', 'CHALLANGES')}
              />
            </GridBlock>
          </FormLayout>
        </ModalOne>
      )
    }
  }
