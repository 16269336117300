import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { SelectOne } from '../../../../lib/select'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'

import { getReduceState } from '../../../../funcs/BASES/getStateOfRed'
import { showMultipleMessages } from '../../../../funcs/BASES/showErrorMultiMessages'

export default class AddModal extends Component {
  constructor(props) {
    super(props)
    this.state = {

      name: '',
      description: '',
      startTime: '',
      endTime: ''
    }
  }

  submit = () => {


    if (this.state.name == null || this.state.name == '') {
      showMultipleMessages("Öğün adı eklenmiş olmalıdır.", 'warning')
    } else if (this.state.description == null || this.state.description == '') {
      showMultipleMessages("Öğün açıklaması eklenmiş olmalıdır.", 'warning')
    } else if (this.state.startTime == null || this.state.startTime == '') {
      showMultipleMessages("Öğün Başlangıç saati eklenmiş olmalıdır", 'warning')
    } else if (this.state.endTime == null || this.state.endTime == '') {
      showMultipleMessages("Öğün Bitiş saati eklenmiş olmalıdır", 'warning')
    } else {
      this.setState(
        {
          uuid: uuidv4()
        },
        () => {
          this.props.fakeThis.setState(
            {
              datas: [...this.props.fakeState.datas, this.state],
              modalIsOpen: false
            },
            () => {
              this.setState({
                name: '',
                description: '',
                startTime: '',
                endTime: ''
              })
            }
          )
        }
      )
    }


  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const AlldetailsReducers = getReduceState('AlldetailsReducers')
    return (
      <ModalOne
        open={this.props.open}
        bgDropColor={'rgba(128, 2, 255, 0.2)'}
        fullScreen={true}
      >
        <FormLayout
          naviIcon={<FaWpforms size={30} color={'white'} />}
          recordIcon={<FaSave size={30} color={'white'} />}
          recordAction={this.submit}
          // filesIcon={<FaFolder size={30} color={'white'} />}
          activeBack={() => {
            this.props.fakeThis.setState({ modalIsOpen: false })
          }}
          loading={false}
        >
          <GridBlock
            title={getTranslatation('egzersizehareketbağlayın', 'PROGRAMS')}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
            <InputOne
              type={'text'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='name'
              label={getTranslatation('name', 'PROGRAMS')}
              name='name'
              value={this.state.name}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('nameHelper', 'PROGRAMS')}
            />


            <InputOne
              type={'text'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='description'
              label={getTranslatation('description', 'PROGRAMS')}
              name='description'
              value={this.state.description}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('descriptionHelper', 'PROGRAMS')}
            />


            <InputOne
              type={'time'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='startTime'
              label={getTranslatation('startTime', 'PROGRAMS')}
              name='startTime'
              value={this.state.startTime}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('startTimeHelper', 'PROGRAMS')}
            />




            <InputOne
              type={'time'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='endTime'
              label={getTranslatation('endTime', 'PROGRAMS')}
              name='endTime'
              value={this.state.endTime}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('endTimeHelper', 'PROGRAMS')}
            />



          </GridBlock>
        </FormLayout>
      </ModalOne>
    )
  }
}
