import * as Types from '../types/Egzersizler'
import Initials from '../initials/Egzersizler'

export default function Reducers (state = Initials, action) {
  switch (action.type) {
    //*********************************************** */  SUCCESS

    case Types.GETALL_SUCCESS:
      return {
        ...state,
        All: action.payload,
        messages: [],
        CustomID: Math.random()
      }

    case Types.GETONE_SUCCESS:
      return {
        ...state,
        One: action.payload,
        messages: [],
        CustomID: Math.random()
      }

    case Types.CREATE_SUCCESS:
      return {
        ...state,
        All: state.All.concat(action.payload),
        messages: [],
        CustomID: Math.random()
      }

    case Types.UPDATE_SUCCESS:
      var foundedObj = state.All.find(
        aa => aa.egzersiz_id == action.payload?.egzersiz_id
      )
      foundedObj = action.payload
      return {
        ...state,
        All: state.All,
        messages: [],
        CustomID: Math.random()
      }

    case Types.DELETE_SUCCESS:
      var clearedDatas = state.All.filter(
        aa => aa.egzersiz_id != action.payload?.egzersiz_id
      )
      return {
        ...state,
        All: clearedDatas,
        messages: [],
        CustomID: Math.random()
      }
      
    default:
      return state
  }
}
