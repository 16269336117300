import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../../components/funcs/BASES'
import queryString from 'query-string'

import ReactCardFlip from 'react-card-flip'

import { LanguageSelector } from '../../components/features/BASES'
import { InputOne } from '../../components/lib/input'
import { ButtonOne, IconButton } from '../../components/lib/button'
import { GridBlock } from '../../components/lib/grid'
import { AnimateOne } from '../../components/lib/animation'
import { Logo, LoginLottie } from '../../components/features/Login'
import { getTranslatation } from '../../locales/translate'

import TranslateIcon from '@mui/icons-material/Translate'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import { LottiePlayerOne } from '../../components/lib/lottie'
import * as animationData from '../../asserts/lotties/lf30_editor_fohvsvls.json'

import * as LanguageActions from '../../redux/actions/Language'
import * as AuthActions from '../../redux/actions/Auth'

import styles from './styles'

class index extends Component {
  // ********************* STATES
  constructor (props) {
    super(props)
    this.state = {
      //states
      loginOnProcess: false,

      // forms
      loginType: 'partnerPanel',
      mailOrPhone: '',
      password: '',

      // errors
      mailOrPhone_ERROR: '',
      password_ERROR: ''
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  girisYap = () => {
    this.setState({ loginOnProcess: true }, () => {
      this.props.actions.Login(
        this.state,
        () => {},
        () => {
          setTimeout(() => {
            this.setState({ loginOnProcess: false })
          }, 3500)
        },
        data => {
          localStorage.setItem('myJWTisHERE', data)
        },
        () => {}
      )
    })
  }

  render () {
    const { classes } = this.props
    return (
      <Grid
        container
        component='main'
        style={{ overflow: 'hidden' }}
        className={classes.root}
      >
        <LanguageSelector fakeThis={this} />
  
        <LoginLottie lg={8} />
  
      

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          component={Paper}
          elevation={0}
          square
        >
          <AnimateOne animation={'bounceInDown'} >
            <GridBlock
              style={{ paddingTop: 60, paddingLeft: 50, paddingRight: 50 }}
            >
              <ReactCardFlip
                isFlipped={this.state.loginOnProcess == false}
                flipDirection='vertical'
              >
                <GridBlock
                  style={{
                    display: this.state.loginOnProcess ? 'block' : 'none'
                  }}
                >
                  <LottiePlayerOne
                    animationData={animationData}
                    name={this.state.name}
                    lastname={this.state.lastname}
                    emailAdress={this.state.emailAdress}
                    selectedLanguage={this.state.selectedLanguage}
                  />
                </GridBlock>

                <GridBlock container spacing={1}>
                  <Logo />

                  <InputOne
                    color={'success'}
                    variant='filled'
                    margin='none'
                    required
                    fullWidth
                    xs={12}
                    sm={12}
                    lg={12}
                    id='mailOrPhone'
                    label={getTranslatation('mailPhoneUsername', 'LOGIN')}
                    name='mailOrPhone'
                    value={this.state.mailOrPhone}
                    onChange={this.setVal}
                    error={this.state.mailOrPhone_ERROR.length > 0}
                    helperText={getTranslatation(
                      'mailPhoneUsernameHelper',
                      'LOGIN'
                    )}
                  />

                  <InputOne
                    color={'success'}
                    variant='filled'
                    margin='none'
                    required
                    fullWidth
                    xs={12}
                    sm={12}
                    lg={12}
                    id='password'
                    label={getTranslatation('sifreniz', 'LOGIN')}
                    name='password'
                    value={this.state.password}
                    onChange={this.setVal}
                    error={this.state.password_ERROR.length > 0}
                    helperText={getTranslatation('sifrenizHelper', 'LOGIN')}
                  />
                  <GridBlock container spacing={1}>
                    <ButtonOne
                      xs={12}
                      sm={12}
                      lg={12}
                      type='submit'
                      fullWidth
                      variant='contained'
                      color={'secondary'}
                      style={{ backgroundColor: '#8002ff' }}
                      className={classes.submit}
                      onClick={this.girisYap}
                      disabled={
                        this.state.loginOnProcess === false ? false : true
                      }
                      text={getTranslatation('login', 'LOGIN')}
                    />
                  </GridBlock>
                  {/* <GridBlock container spacing={1}>
                  <IconButton
                    icon={<TranslateIcon style={{ fontSize: 35 }} />}
                    onClick={() => {
                      this.props.actions.ChangeLanguage('')
                    }}
                  />
                </GridBlock> */}
                </GridBlock>
              </ReactCardFlip>
            </GridBlock>
            <GridBlock
              style={{ paddingTop: 10, paddingLeft: 50, paddingRight: 50 }}
            >
              <Box mt={2} style={{ textAlign: 'center' }}>
                {getTranslatation('explainSub', 'LOGIN')}
              </Box>
            </GridBlock>
          </AnimateOne>
        </Grid>
      </Grid>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    LanguageReducersData: state.LanguageReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      ChangeLanguage: bindActionCreators(
        LanguageActions.ChangeLanguage,
        dispatch
      ),

      Login: bindActionCreators(AuthActions.Login, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index))
)
