import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Ad',
      selector: 'userName',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.name}</div>
    },

    {
      name: 'Soyad',
      selector: 'userLastName',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.lastname}</div>
    },
    {
      name: 'Mail',
      selector: 'mails_text',
      sortable: true,
      cell: row => <div style={{ fontSize: 14, fontWeight: row.isConfirmMail == 1 ? 'bold' : 'normal' }}>{row.mails_text}</div>
    },

    {
      name: 'Program',
      selector: 'paid_program_count',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.paid_program_count}</div>
    },

    {
      name: 'Aktif Program',
      selector: 'have_active_program',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.have_active_program == 1 ? 'VAR' : 'YOK'}</div>
    },

    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.users_id)}
        // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.users_id)}
        // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
