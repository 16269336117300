import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import { Dashboard } from '../../views/Dashboard'

export default class DashboardRoute extends Component {
  render () {
    return (
      <Routes>
        <Route path='/' exact element={<Dashboard />} />
      </Routes>
    )
  }
}
