import React, { Component } from 'react'
import { GridBlock } from '../../lib/grid'

import FormHeader from '../BASES/FormHeader'

export default class MainPageLayout extends Component {
  render () {
    return (
      <GridBlock
        display={'flex'}
        direction={this.props.direction}
        container={this.props.container}
        justify={this.props.justify}
        align={this.props.align}
        style={this.props.style}
      >
        {this.props.children}
      </GridBlock>
    )
  }
}


MainPageLayout.defaultProps={
  justify:'flex-start',
  align:'flex-start',
  style:{ padding: 5, minWidth: '100%', alignSelf: 'center'},
  container: false
}
