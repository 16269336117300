import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

const styles = theme => ({
  logoImage: {
    objectFit: 'contain',
    width: 320,
    marginBottom:15
  }
})

class Logo extends Component {
  render () {
    const { classes } = this.props
    return (
      <Grid item xs={12} sm={12} md={12} elevation={0} square>
        <Box mt={0} style={{ textAlign: 'center' }}>
          <img
            className={classes.logoImage}
            src={require('../../../asserts/images/loginlogo.png')}
          />
        </Box>
      </Grid>
    )
  }
}

export default withStyles(styles)(Logo)
