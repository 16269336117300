import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'




export const Cols = ( onRowClickedDelete , Hareketler) => {
  return [
    {
      name: 'Set',
      selector: 'egzSet',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.egzSet}</div>
    },

    {
      name: 'Tekrar',
      selector: 'tekrar',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.tekrar}</div>
    },

    {
      name: 'Hareket',
      selector: 'hareketler_hareketler_id',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>

 
          {
            Hareketler?.find(
              aa => aa.value == row.hareketler_hareketler_id
            )?.text
          }
        </div>
      )
    },
   

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.uuid)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
