import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../components/funcs/BASES'
import queryString from 'query-string'

import { Routes, Route, Navigate } from 'react-router-dom'

import { TopMenu, LeftMenu } from '../components/features/Menu'
import { ScrollContainerOne } from '../components/lib/scrollContainer'

import Login from '../views/Login'

import {
  Actions,
  Appreciations,
  Changes,
  Dashboard,
  Exercise,
  Notifications,
  Nutrition,
  Programs,
  Users,
  Workout,
  Payments,
  Challanges
} from './parts'

import * as AllDetailsActions from '../redux/actions/Alldetails'

class BaseRoutes extends Component {
  componentDidMount () {
    this.props.actions.GetAllDetails(
      null,
      () => {},
      () => {},
      () => {},
      () => {}
    )
  }

  render () {
    return (
      <div style={{ width: '100%' }}>
        {this.props.AuthReducers.isAuthenticated == false ? (
          <Routes>
            <Route path='*' exact element={<Login />} />
          </Routes>
        ) : (
          <div
            style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}
          >
            <LeftMenu />
            <ScrollContainerOne>
              <Actions/>
              <Appreciations/>
              <Changes/>
              <Dashboard/>
              <Exercise/>
              <Notifications/>
              <Nutrition/>
              <Programs/>
              <Users/>
              <Workout/>
              <Payments/>
              <Challanges/>
            </ScrollContainerOne>
          </div>
        )}
      </div>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    AuthReducers: state.AuthReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      GetAllDetails: bindActionCreators(
        AllDetailsActions.GetAllDetails,
        dispatch
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseRoutes)
