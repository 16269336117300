import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import { withRouter } from '../../funcs/BASES'
import { GridBlock } from '../../lib/grid'
import { AnimateOne } from '../../lib/animation'

import { FaEject } from 'react-icons/fa'

const styles = theme => ({
  root: {
    backgroundColor: '#e0e0e0',
    // width: '100%',
    height: 50,
    maxHeight: 50,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 10,
    marginBottom: 15
    // backgroundImage:'linear-gradient(to right, black , white);'
  },
  iconArea: {
    backgroundColor: 'black',
    padding: 4,
    height: 42,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginRight: 5
  },
  rightIconTotalArea: {
    display: 'flex',
    flexDirection: 'row'
  }
})

class EjectButton extends Component {
  render () {
    return (
      <div
        className={this.props.className}
        onClick={() => {
          this.props.activeBack == null
            ? this.props.router.navigate(-1)
            : this.props.activeBack()
        }}
      >
        <FaEject style={{ fontSize: 30 }} color={'white'} />
      </div>
    )
  }
}

class FormHeader extends Component {
  render () {
    const { classes } = this.props
    return (
      <GridBlock>
        <AnimateOne delay={0} animation={'fadeIn'}>
          <GridBlock justify={'space-between'} className={classes.root}>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <AnimateOne>
                <div
                  style={{ backgroundColor: '#8002ff', width: 10, height: 50 }}
                />
              </AnimateOne>
              <AnimateOne>
                <div>
                  {this.props.naviIcon != null && (
                    <div className={classes.iconArea}>
                      {this.props.naviIcon}
                    </div>
                  )}
                </div>
              </AnimateOne>
            </div>
            <div className={classes.rightIconTotalArea}>
              <AnimateOne>
                <EjectButton
                  className={classes.iconArea}
                  router={this.props.router}
                  activeBack={this.props.activeBack}
                />
              </AnimateOne>

              {this.props.filesIcon != null && (
                <AnimateOne>
                  <div className={classes.iconArea}>{this.props.filesIcon}</div>
                </AnimateOne>
              )}

              {this.props.recordIcon != null && (
                <AnimateOne>
                  <div
                    className={classes.iconArea}
                    onClick={this.props.recordAction}
                  >
                    {this.props.recordIcon}
                  </div>
                </AnimateOne>
              )}
              {/* {this.props.recordORupdateIcon != null && (
            <div className={classes.iconArea}>{this.props.updateIcon}</div>
          )} */}
            </div>
          </GridBlock>
        </AnimateOne>
      </GridBlock>
    )
  }
}

FormHeader.defaultProps = {
  date: '...',
  icon: ''
}

FormHeader.propTypes = {
  title: PropTypes.string
}

export default withRouter(withStyles(styles)(FormHeader))
