import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTranslatation } from '../../locales/translate'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'

import { TableOne } from '../../components/lib/table'
import { Cols } from '../../components/features/TableCols/Exercise'
import { FaSearch, FaPlus } from 'react-icons/fa'
import { InputOne } from '../../components/lib/input'
import { IconButton } from '../../components/lib/button'
import { GridBlock } from '../../components/lib/grid'
import { ParallaxCard } from '../../components/lib/cards'

import endPoints from '../../components/Consts/endPoints.json'
import * as EgzersizlerActions from '../../redux/actions/Egzersizler'  


class Exercise extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loginOnProcess: false,
      searchWord: ''
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Exercise')?.routes
  }

  componentDidMount () {
    this.props.actions.GetAll(
      null,
      () => {
        this.setState({ loginOnProcess: false })
      },
      () => {
        setTimeout(() => {
          this.setState({ loginOnProcess: false })
        }, 1000)
      },
      () => {},
      () => {}
    )
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  update = id => {
    this.props.router.navigate(this.releatedRoutes.create, {
      replace: true,
      state: { id }
    })
  }

  delete = id => {
    // this.props.actions.Delete(
    //   id,
    //   () => {},
    //   () => {},
    //   () => {},
    //   () => {}
    // )
  }

  deleteConfirm = id => {
    confirmAlert({
      title: 'You are about to delete the driver, are you sure?',
      message:
        'To continue with the deletion, please confirm or cancel your transaction.',
      buttons: [
        {
          label: 'Approve',
          onClick: () => this.delete(id)
        },
        {
          label: 'Give Up'
          // onClick: () => alert('Click No')
        }
      ]
    })
  }

  render () {
    return (
      <GridBlock style={{ padding: 10 }}>
        <TableOne
          title={getTranslatation('tableTitle','EXERCISE')}
          responsive={true}
          subHeader={true}
          columns={Cols(this.update, this.deleteConfirm)}
          data={this.props.EgzersizlerReducers?.All}
          subHeaderComponent={
            <GridBlock  container  justify={'space-between'}  align={'space-between'} style={{ padding:10  }}>
              <IconButton
                icon={<FaPlus />}
                onClick={() => {
                  this.props.router.navigate(this.releatedRoutes.create)
                }}
              />

              <InputOne
                color={'success'}
                variant='filled'
                margin='none'
                required
                fullWidth
                xs={10}
                sm={6}
                lg={4}
                id='searchWord'
                label={getTranslatation('aramayap')}
                placeHolder={'Arama Yap'}
                name='searchWord'
                value={this.state.searchWord}
                onChange={this.setVal}
                // error={this.state.password_ERROR.length > 0}
                helperText={getTranslatation('aramayap')}
                endAdornment={<FaSearch />}
              />
            </GridBlock>
          }
        />

        <ParallaxCard
          imageOne={require('../../asserts/images/cards/yoga/camel.png')}
          imageTwo={require('../../asserts/images/cards/flower4.png')}
          imageThree={require('../../asserts/images/cards/flowers3.png')}
          imageFour={require('../../asserts/images/cards/flower.png')}
        />
        
      </GridBlock>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    EgzersizlerReducers: state.EgzersizlerReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      GetAll: bindActionCreators(EgzersizlerActions.GetAll, dispatch),
      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exercise))
