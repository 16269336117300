import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTranslatation } from '../../locales/translate'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'

import { TableOne } from '../../components/lib/table'
import { Cols } from '../../components/features/TableCols/Users'
import { FaSearch, FaPlus } from 'react-icons/fa'
import { InputOne } from '../../components/lib/input'
import { IconButton } from '../../components/lib/button'
import { GridBlock } from '../../components/lib/grid'
import { ParallaxCard } from '../../components/lib/cards'

import endPoints from '../../components/Consts/endPoints.json'
import * as UsersActions from '../../redux/actions/Users'


class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loginOnProcess: false,
      searchWord: ''
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Users')?.routes
  }

  componentDidMount () {
    this.props.actions.GetAll(
      null,
      () => {
        this.setState({ loginOnProcess: false })
      },
      () => {
        setTimeout(() => {
          this.setState({ loginOnProcess: false })
        }, 1000)
      },
      () => {},
      () => {}
    )
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  update = id => {
    this.props.router.navigate(this.releatedRoutes.update, {
      replace: true,
      state: { id }
    })
  }

  delete = id => {
    this.props.actions.Delete(
      id,
      () => {},
      () => {},
      () => {},
      () => {}
    )
  }

  deleteConfirm = id => {
    confirmAlert({
      title: 'VERI KAYBI UYARISI',
      message: "Devam etmek için, TAMAM butonuna, Vazgeçmek için VAZGEÇ butonuna basınız.",
      buttons: [
        {
          label: 'TAMAM',
          onClick: () => this.delete(id)
        },
        {
          label: 'VAZGEÇ'
          // onClick: () => alert('Click No')
        }
      ]
    })
  }


  filteredData = () => {
    let data = this.props.UsersReducers?.All?.filter(user => user.isDeletedUser == 0)
    if (this.state.searchWord.length > 0) {
      data = data.filter(user => {
        return (
          user?.mails_text.toLowerCase().includes(this.state.searchWord.toLowerCase()) ||
          user?.name.toLowerCase().includes(this.state.searchWord.toLowerCase()) ||
          user?.lastname.toLowerCase().includes(this.state.searchWord.toLowerCase())
        )
      })
    }
    return data
  }


  render () {
    return (
      <GridBlock style={{ padding: 10 }}>
        <TableOne
          title={getTranslatation('tableTitle','USERS')}
          responsive={true}
          subHeader={true}
          columns={Cols(this.update, this.deleteConfirm)}
          data={this.filteredData()}
          conditionalRowStyles={[
            {
              when: row => row.have_active_program == 1,
              style: {
                backgroundColor: '#b5ffb3'
              }
            }
          ]}
          pagination={true}
          subHeaderComponent={
            <GridBlock  container  justify={'space-between'}  align={'space-between'} style={{ padding:10  }}>
              <IconButton
                icon={<FaPlus />}
                onClick={() => {
                  this.props.router.navigate(this.releatedRoutes.create)
                }}
              />

              <InputOne
                color={'success'}
                variant='filled'
                margin='none'
                required
                fullWidth
                xs={10}
                sm={6}
                lg={4}
                id='searchWord'
                label={'Arama Yap'}
                maskPlaceholder={'Arama Yap'}
                name='searchWord'
                value={this.state.searchWord}
                onChange={this.setVal}
                // error={this.state.password_ERROR.length > 0}
                helperText={"Arama Yapmak İçin Kelime Giriniz - ad / soyad / mail"}
                endAdornment={<FaSearch />}
              />
            </GridBlock>
          }
        />

          <ParallaxCard
            imageOne={require('../../asserts/images/cards/yoga/crow.png')}
            imageTwo={require('../../asserts/images/cards/butterflies.png')}
            imageThree={require('../../asserts/images/cards/cherry-blossom.png')}
            imageFour={require('../../asserts/images/cards/flowers.png')}
          />
      </GridBlock>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    UsersReducers: state.UsersReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      GetAll: bindActionCreators(UsersActions.GetAll, dispatch),
      Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users))
