import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'

import { Nutrition, NutritionForm } from '../../views/Nutrition'

export default class NutritionRoute extends Component {
  render () {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Nutrition')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Nutrition />} />
        <Route path={releatedRoutes?.create} exact element={<NutritionForm />} />
        <Route path={releatedRoutes?.update} exact element={<NutritionForm />} />
        <Route path={releatedRoutes?.delete} exact element={<NutritionForm />} />
      </Routes>
    )
  }
}
