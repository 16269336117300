import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Ad',
      selector: 'name',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.programName}</div>
    },

    {
      name: 'Fiyat',
      selector: 'price',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.price}</div>
    },

    {
      name: 'Seviye',
      selector: 'fitnessLevelID',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.fitnessLevelID}</div>
    },



    {
      name: 'Antrenmanları',
      selector: 'antrenmanCount',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.antrenmanCount}</div>
    },
    


    {
      name: 'Egzersizleri',
      selector: 'egzersizCount',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.egzersizCount}</div>
    },

    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.program_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.program_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
