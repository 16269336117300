import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'ID',
      selector: 'hareketler_id',
      sortable: true,
      width: '50px',
      cell: row => <div style={{ fontSize: 14 }}>{row.hareketler_id}</div>
    },

    {
      name: 'Ad',
      selector: 'name',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.name}</div>
    },

    {
      name: 'Sure',
      selector: 'timeSecond',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.timeSecond}</div>
    },
    {
      name: 'Kalori',
      selector: 'calory',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.calory}</div>
    },

    { 
      name: 'Statik',
      selector: 'isStatic',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.isStatic == 1 ? 'STATIC' : 'NON-STATIC'}</div>
    },



    { 
      name: 'Steps',
      selector: 'stepDesc',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.stepDesc?.length}</div>
    },
    

    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.hareketler_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.hareketler_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
