import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import notifyTargets from '../../components/Consts/notifyTargets.json'
import notificationTypes from '../../components/Consts/notificationTypes.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaEnvelope } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'

import * as DegerlendirmelerActions from '../../redux/actions/Degerlendirmeler'
import * as AlldetailsActions from '../../redux/actions/Alldetails'

// ** 1- Aktif Bir Programi olan kullanıcılar
// ** 2- Tüm Kullanıcılar
// ** 3- Hiç satın alım yapmamış kullanıcılar
// ** 4- Aktif Bir Programi olmayan fakat herhangi bir programı daha oncee satın almıs olan kullanıcılar
//************************************************************** */
// ** 5- aynı gun antrenmanı olanlar
// ** 6- aynı gun antrenmanını tamamlamayanlar
// ** 7- su akdar gun gecmiste dukadar antrenman yapmayanlar
// ** 8- su akdar gun gecmiste dukadar antrenman yapanlara
class AppreciationsForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      onProcess: false,

      degerlendimeler_id: '',

      degerlendirme_point: '',
      degerlendirme_test: '',
      degerlendirme_order: 0

    }
    this.releatedRoutes = endPoints.find(
      aa => aa.name == 'Appreciations'
    )?.routes
  }

  componentDidMount () {
    this.props.actions.GetAllDetails(
      null,
      () => {},
      () => {},
      () => {},
      () => {}
    )
    try {
      var id = this.props.router.location.state.id
      this.setState({ degerlendimeler_id: id }, () => {
        this.props.actions.GetOne(
          this.state.degerlendimeler_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => {}
        )
      })
    } catch (err) {}
  }

  setPageForGETID = data => {
    this.setState({
      degerlendirme_point: data?.degerlendirme_point,
      degerlendirme_test: data?.degerlendirme_test,
      degerlendirme_order: data?.degerlendirme_order,
    })
  }

  submit = () => {
 
    // var id = this.props.router?.location?.state?.id
    // if (id == null || id == '') {
    //   // CREATE
    //   this.props.actions.Create(
    //     {
    //       degerlendirme_point: this.state?.degerlendirme_point,
    //       degerlendirme_test: this.state?.degerlendirme_test,
    //     },
    //     () => {
    //       this.setState({ onProcess: true })
    //     },
    //     () => {
    //       setTimeout(() => {
    //         this.setState({ onProcess: false })
    //       }, 500)
    //     },
    //     () => {
    //       this.props.router.navigate(this.releatedRoutes.get)
    //     },
    //     () => {}
    //   )
    // } else {
      // UPDATE
      this.props.actions.Update(
        {
          degerlendimeler_id: this.state?.degerlendimeler_id,
          degerlendirme_point: this.state?.degerlendirme_point,
          degerlendirme_test: this.state?.degerlendirme_test,
          degerlendirme_order: this.state?.degerlendirme_order,
        },
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => {}
      )
    // }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    return (
      <FormLayout
        naviIcon={<FaEnvelope size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={null}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >
        <GridBlock
          title={endPoints.find(aa => aa.name == 'Appreciations')?.menutext}
          container={true}
          xs={12}
          sm={12}
          lg={12}
        >


             
          <InputOne
            multiline={false}
            minRows={1}
            color={'success'}
            variant='filled'
            margin='none'
            required
            fullWidth
            xs={12}
            sm={12}
            lg={4}
            id='degerlendirme_order'
            label={getTranslatation('degerlendirme_order', 'APPRECIATIONS')}
            name='degerlendirme_order'
            value={this.state.degerlendirme_order}
            onChange={this.setVal}
            // error={this.state.mailOrPhone_ERROR.length > 0}
            helperText={getTranslatation('degerlendirme_orderHelper', 'APPRECIATIONS')}
          />

        
          <InputOne
            multiline={false}
            minRows={1}
            color={'success'}
            variant='filled'
            margin='none'
            required
            fullWidth
            xs={12}
            sm={12}
            lg={8}
            id='degerlendirme_point'
            label={getTranslatation('degerlendirme_point', 'APPRECIATIONS')}
            name='degerlendirme_point'
            value={this.state.degerlendirme_point}
            // onChange={this.setVal}
            // error={this.state.mailOrPhone_ERROR.length > 0}
            helperText={getTranslatation('degerlendirme_pointHelper', 'APPRECIATIONS')}
          />

          <InputOne
            multiline={true}
            minRows={8}
            color={'success'}
            variant='filled'
            margin='none'
            required
            fullWidth
            xs={12}
            sm={12}
            lg={12}
            id='degerlendirme_test'
            label={getTranslatation('degerlendirme_test', 'Appreciations')}
            name='degerlendirme_test'
            value={this.state.degerlendirme_test}
            // onChange={this.setVal}
            // error={this.state.mailOrPhone_ERROR.length > 0}
            helperText={getTranslatation('degerlendirme_testHelper', 'Appreciations')}
          /> 
        </GridBlock>
      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(DegerlendirmelerActions.GetOne, dispatch),
      Update: bindActionCreators(
        DegerlendirmelerActions.Update,
        dispatch
      ),

      GetAllDetails: bindActionCreators(
        AlldetailsActions.GetAllDetails,
        dispatch
      ),

 


      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppreciationsForm)
)
