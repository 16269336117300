import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import Compressor from 'compressorjs';
import Dropzone from 'react-dropzone'

import styled from 'styled-components'
import LinearProgress from '@mui/material/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { GridBlock } from '../../lib/grid'

import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { confirmAlert } from 'react-confirm-alert'

import * as StorageActions from '../../../redux/actions/Storage';


function canIuploadMore(loaded, loading, limit) {
  var total = loaded + loading
  if (total + 1 > limit) {
    return false
  } else {
    return true
  }
}

class UploadOne extends Component {
  constructor() {
    super()
    this.state = {
      onProgress: 0,
      files: []
    }
  }

  onDrop = files => {

    var ffThis = this


    files.map(file => {
      if(!file.type.includes("image")){

        var params = {
          files: [file],
          cascadeID: this.props.cascadeID,
          langID: 'TR',
          formID: this.props.formID,
          isAws: 1, // userid
          ownerID: this.props.AuthReducersData?.jwtDecodedToken?.users_id
        }

        // console.log(canIuploadMore(this.state.onProgress,this.state.files.length,this.props.allowedLength),'sssssssssssssssssssssssssss')
        // console.log(this.state.onProgress,this.state.files.length,this.props.allowedLength,'aaaaaaaaaaaaaaaaa')
        if (canIuploadMore(this.state.onProgress, this.state.files.length, this.props.allowedLength)) {
          this.props.actions.Upload(
            params,
            () => {
              this.setState({
                onProgress: ffThis.state.onProgress + 1
              })
            },
            () => {
              this.setState({
                onProgress: ffThis.state.onProgress - 1
              })
            },
            data => {
              console.log(data, 'datadatadatadatadatadatadata')
              ffThis.addUploadedFiles(data)
            },
            data => {
              console.log(data, 'data2')
            }
          )
        }

      }else{
        new Compressor(file, {
          quality: 0.4,
          maxWidth: 1200,
          convertTypes: ['image/jpeg', 'image/png'],
          success: (compressedResult) => {
  
            var params = {
              files: [compressedResult],
              cascadeID: this.props.cascadeID,
              langID: 'TR',
              formID: this.props.formID,
              isAws: 1, // userid
              ownerID: this.props.AuthReducersData?.jwtDecodedToken?.users_id
            }
  
            // console.log(canIuploadMore(this.state.onProgress,this.state.files.length,this.props.allowedLength),'sssssssssssssssssssssssssss')
            // console.log(this.state.onProgress,this.state.files.length,this.props.allowedLength,'aaaaaaaaaaaaaaaaa')
            if (canIuploadMore(this.state.onProgress, this.state.files.length, this.props.allowedLength)) {
              this.props.actions.Upload(
                params,
                () => {
                  this.setState({
                    onProgress: ffThis.state.onProgress + 1
                  })
                },
                () => {
                  this.setState({
                    onProgress: ffThis.state.onProgress - 1
                  })
                },
                data => {
                  console.log(data, 'datadatadatadatadatadatadata')
                  ffThis.addUploadedFiles(data)
                },
                data => {
                  console.log(data, 'data2')
                }
              )
            }
  
  
          },
        });
      }
    

    })





  }

  addUploadedFiles = (data) => {
    this.setState({ files: this.state.files.concat(data) }, () => {
      var fileIDS = []
      var uniqueFileList = []
      this.state.files.map(file => {
        if (!fileIDS.includes(file?.storageIds)) {
          fileIDS.push(file?.storageIds)
          uniqueFileList.push(file)
        }
      })
      this.setState({ files: uniqueFileList })
    });

  }





  removeAFile = uuid => {

    var ffThis = this
    this.props.actions.Delete(
      uuid,
      () => { },
      () => { },
      () => {

        console.log(ffThis.state.files, uuid)
        var files = ffThis.state.files.filter(
          file => file.uuid != uuid
        )
        ffThis.setState({ files })
      },
      () => { }
    )
  }

  onRowClickedDelete = id => {
    confirmAlert({
      title: 'Yüklemiş olduğunuz öğe kaldırılıcak, onaylıyormusunuz?',
      message: 'devam etmek için Tamam butonuna basınız.',
      buttons: [
        {
          label: 'Tamam',
          onClick: () => this.removeAFile(id)
        },
        {
          label: 'Vazgeç'
          // onClick: () => alert('Click No')
        }
      ]
    })
  }

  filePreview(file, xs, sm, lg, full = false) {
    const { classes } = this.props
    return (
      <Grid item xs={xs} sm={sm} lg={lg} style={{ padding: 10 }}>
        <Card raised={true}>
          <div
            className={classes.controls}
            onClick={() => {
              this.onRowClickedDelete((file?.uuid?.length > 0 ? file?.uuid : file?.storageIds))
            }}
          >
            <VisibilityIcon aria-label='see Image'>
              <CloudOffIcon className={classes.playIcon} />
            </VisibilityIcon>

            <IconButton aria-label='remove Image'>
              <CloudOffIcon
                className={classes.playIcon}
                style={{ color: 'red' }}
              />
            </IconButton>
          </div>

          <div className={classes.details}>
            <CardContent className={classes.content}>
              {full === true ? (
                <>
                  <Typography component='h6' variant='h6'>
                    {file.filename}
                  </Typography>
                </>
              ) : null}
            </CardContent>
          </div>

          {
            (' ' + file?._mimetype + file?.mimetype + file?.type).includes('image') ? (
              <img
                onClick={() => {
                  window.open(process.env.REACT_APP_STORAGE_URL + 'Storage/' + (file?.uuid == null ? file?.storageIds : file?.uuid))
                }}
                src={
                  process.env.REACT_APP_STORAGE_URL + 'Storage/' + (file?.uuid == null ? file?.storageIds : file?.uuid)
                }
                className={full === true ? classes.coverBig : classes.cover}
              />
            )
              :
              <img
                onClick={() => {
                  window.open(process.env.REACT_APP_STORAGE_URL + 'Storage/' + (file?.uuid == null ? file?.storageIds : file?.uuid))
                }}
                src={require('../../../asserts/images/videos.png')}
                className={full === true ? classes.coverBig : classes.cover}
              />
          }

        </Card>
      </Grid>
    )
  }

  dropArea() {
    const { classes } = this.props
    const { xs, sm, lg, _xs, _sm, _lg } = this.props
    const dropAreaBG =
      this.state.files.length + 1 > this.props.allowedLength
        ? '#d7f5da'
        : !canIuploadMore(
          this.state.onProgress,
          this.state.files.length,
          this.props.allowedLength
        )
          ? '#fad419'
          : '#f7f7f7'
    return (
      <Grid
        container
        xs={xs}
        sm={sm}
        md={sm}
        lg={lg}
        style={{ padding: 10 }}
        justify='flex-start'
        align='flex-start'
      >
        {/* {this.state.onProgress == true && (
          <LinearProgress
            color='inherit'
            style={{
              marginBottom: 40,
              marginLeft: 20,
              width:'100%'
            }}
          />
        )} */}

        <Grid item xs={12} sm={12} lg={12} style={{ padding: 10 }}>
          <Dropzone
            onDrop={this.onDrop}
            accept={this.props.acceptFiles}
            disabled={
              !canIuploadMore(
                this.state.onProgress,
                this.state.files.length,
                this.props.allowedLength
              )
            }
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject
            }) => (
              <Container
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
                id='DragDropArea'
                style={{
                  backgroundColor: dropAreaBG,
                  borderRadius: 20
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <input {...getInputProps()} />

                  {this.props.uploadIcon == null ? (
                    <CloudQueueIcon style={{ fontSize: 60 }} />
                  ) : (
                    this.props.uploadIcon
                  )}
                  <br />
                  {this.props.dropLabel}
                </div>
              </Container>
            )}
          </Dropzone>
        </Grid>

        {[...Array(this.state.onProgress)].map((e, i) => {
          return (
            <Grid item xs={_xs} sm={_sm} lg={_lg} style={{ padding: 10 }}>
              <Card raised={true}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <LinearProgress
                      color='inherit'
                      style={{ width: '100%', height: 253, marginBottom: -5 }}
                    />
                  </CardContent>
                </div>
              </Card>
            </Grid>
          )
        })}

        {this.state.files.map(file => {
          return this.filePreview(file, _xs, _sm, _lg)
        })}
      </Grid>
    )
  }

  render() {
    const { xs, sm, lg } = this.props
    return this.dropArea()
  }
}

const styles = theme => ({
  rootBig: {
    height: 200,
    marginBottom: 10,
    flex: 1,
    display: 'flex',
    minWidth: '100%'
  },
  root: {
    height: 200,
    marginBottom: 10,
    flex: 1,
    display: 'flex',
    minWidth: '100%'
  },
  details: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  coverBig: {
    maxHeight: 400,
    borderRadius: 40,
    left: 10,
    float: 'right',
    clear: 'right',
    transform: 'rotate(-10deg)',
    position: 'relative',
    zIndex: 1
  },
  cover: {
    height: 200,
    // width: 300,
    borderRadius: 40,
    left: 10,
    float: 'right',
    clear: 'right',
    transform: 'rotate(-10deg)',
    position: 'relative',
    zIndex: 1
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  playIcon: {
    height: 38,
    width: 38
  }
})

/********************************************************* */
/********************************************************* */
/********************************************************* */
const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

UploadOne.defaultProps = {
  allowedLength: 99999
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    LanguageReducersData: state.LanguageReducers,
    AuthReducersData: state.AuthReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      Upload: bindActionCreators(StorageActions.Upload, dispatch),
      Delete: bindActionCreators(StorageActions.Delete, dispatch),
      GetOne: bindActionCreators(StorageActions.GetOne, dispatch)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UploadOne))
