
export default {

    All:[],
    UserConfs: {},
    OneUser: {},
    Antrenmans: [],
    DailyWater: 0,
    Programs: [],
    

    messages: [],
    CustomID: Math.random(),

}