import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../../../components/funcs/BASES/withRouter'
import { GridBlock } from '../../../components/lib/grid'
import queryString from 'query-string'

import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

const styles = theme => ({
  CardContent: {
    marginBottom: 5,
    marginTop: 1,
    marginLeft: 20,
    fontFamily: "'ABeeZee', sans-serif",
    textAlign: 'left',
    flex: 1
  }
})

class CardTwo extends Component {
  render () {
    const { classes } = this.props
    return (
      <GridBlock
        item={true}
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.sm}
        lg={this.props.lg}
        wrap='nowrap'
        style={{
          backgroundColor: this.props.backgroundColor,
          padding: 1,
          paddingBottom: 15,
          paddingLeft:5,
          paddingRight:5
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            alignItems: 'center',
            backgroundColor: this.props.titleBackgroundColor,
          }}
        >
          <div
            style={{ minWidth: 5, height: 40, backgroundColor: 'red', flex: 0 }}
          />
          <h3 class={classes.CardContent}>{this.props.title}</h3>
          <p
            style={{
              textAlign: 'right',
              fontSize: 10,
              margin: 2,
              marginRight: 20
            }}
          >
            {this.props.subQuite}
          </p>
        </div>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 3,
            // maxHeight: this.props.cardHeight,
            minHeight: this.props.cardHeight,
            marginLeft: 0,
            marginRight: 0,
            justifyContent: 'center',
            alignItems: 'center',

            backgroundColor: '#fff'
          }}
        >
          <GridBlock container>{this.props.children}</GridBlock>
        </CardContent>
      </GridBlock>
    )
  }
}

CardTwo.defaultProps = {
  backgroundColor: 'red',
  cardHeight: 150,
  titleBackgroundColor: '#f2f2f2'
}

export default withStyles(styles)(CardTwo)
