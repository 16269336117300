import axios from 'axios'
import { getReduceState } from '../components/funcs/BASES/getStateOfRed'

export const setAxiosConfigs = function (type) {
  if(type == 'storage'){
    axios.defaults.baseURL = process.env.REACT_APP_STORAGE_URL
  }else{
    axios.defaults.baseURL = process.env.REACT_APP_API_URL
  }


  var token = String(getReduceState('AuthReducers').jwtToken)
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
