import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'

export const Cols = (onRowClickedDelete, Exercises) => {
  return [
    {
      name: 'Egzersiz',
      selector: 'egzersiz_egzersiz_id',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {Exercises?.find(aa => aa.value == row.egzersiz_egzersiz_id)?.text}
        </div>
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.uuid)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
