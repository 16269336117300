import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaShapes } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'
import UploadOne from '../../components/lib/upload/UploadOne'


import { Hareketler } from '../../components/features/Exercise'

import * as EgzersizlerActions from '../../redux/actions/Egzersizler'
import * as AlldetailsActions from '../../redux/actions/Alldetails'

class ExerciseForm extends Component {
  constructor(props) {
    super(props)
    this.state = {

      onProcess: false,
      activeSection: 'form', // files

      egzersiz_id: '',

      egzersizName: '',
      hareketler: []
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Exercise')?.routes
    this.ref_fileArea = React.createRef()
  }

  componentDidMount() {
    this.props.actions.GetAllDetails(null, () => { }, () => { }, () => { }, () => { })
    try {
      var id = this.props.router.location.state.id
      this.setState({ egzersiz_id: id }, () => {
        this.props.actions.GetOne(
          this.state.egzersiz_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    this.setState({
      egzersizName: data?.egzersizName,
      hareketler: data?.hareketler
    },
      () => {
        this.ref_fileArea.current.state.files = data?.medias != null ? data?.medias : []
      })
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <FormLayout
        naviIcon={<FaShapes size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.egzersiz_id != null && this.state.egzersiz_id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >

        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>

          <UploadOne
            ref={this.ref_fileArea}
            innerRef={this.ref_fileArea}
            cascadeID={this.state.egzersiz_id}
            showDropAreaWhenFull={true}
            formID={4} // egzersiz image 1 adet
            allowedLength={1}
            xs={12}
            sm={12}
            lg={12}
            _xs={12}
            _sm={12}
            _lg={12}
            _mainContainer
            acceptFiles={'image/jpeg, image/png, image/jpg'}
            dropLabel={
              <div>
                Egzersiz resmi yükleyiniz <br /> en fazla 1 Adet{' '}
              </div>
            }
          />
        </GridBlock>

        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>

          <GridBlock title={endPoints.find(aa => aa.name == 'Exercise')?.menutext} container={true} xs={12} sm={12} lg={12}>
            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='egzersizName'
              label={getTranslatation('egzersizName', 'EXERCISE')}
              name='egzersizName'
              value={this.state.egzersizName}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('egzersizNameHelper', 'EXERCISE')}
            />


            <GridBlock title='Bağlı hareketler' container={true} xs={12} sm={12} lg={12}>
              <Hareketler hareketler={this.state.hareketler} fakeThis={this} />
            </GridBlock>
          </GridBlock>
        </GridBlock>

      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(EgzersizlerActions.GetOne, dispatch),
      Create: bindActionCreators(EgzersizlerActions.Create, dispatch),
      Update: bindActionCreators(EgzersizlerActions.Update, dispatch),

      GetAllDetails: bindActionCreators(AlldetailsActions.GetAllDetails, dispatch)

      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExerciseForm)
)
