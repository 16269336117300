import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import queryString from 'query-string'

import { ModalOne } from '../../lib/modal'
import { GridBlock } from '../../lib/grid'
import { FlagButton } from '../../lib/button'
import Grid from '@material-ui/core/Grid'

// import * as LanguageActions from '../../../redux/actions/Language/LanguageActions'

class LanguageSelector extends Component {
  render () {
    return (
      <ModalOne open={this.props?.LanguageReducersData?.languageID == ''}>
        <GridBlock container spacing={1}>
          <Grid item xs={4} sm={4} lg={4}>
            <FlagButton
              // onClick={() => {
              //   this.props.actions.ChangeLanguage('TR')
              // }}
              xs={12}
              sm={12}
              lg={12}
              text={<div>Türkçe için lütfen burayı seçiniz.</div>}
              backgroundImage={
                'url(https://images.unsplash.com/photo-1629649420958-63171abdd674?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80)'
              }
            />
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <FlagButton
              height='88vh'
              // onClick={() => {
              //   this.props.actions.ChangeLanguage('GR')
              // }}
              xs={12}
              sm={12}
              lg={12}
              text={<div> Für Deutsch bitte hier auswählen</div>}
              backgroundImage={
                'url(https://images.unsplash.com/photo-1628720103883-d370b65339f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)'
              }
            />

          
          </Grid>

          <Grid item xs={4} sm={4} lg={4}>
            <FlagButton
              // onClick={() => {
              //   this.props.actions.ChangeLanguage('EN')
              // }}
              xs={12}
              sm={12}
              lg={12}
              text={<div>For english please select here.</div>}
              backgroundImage={
                'url(https://images.unsplash.com/photo-1566767906131-6042fce44b17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)'
              }
            />
          </Grid>
        </GridBlock>
      </ModalOne>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    LanguageReducersData: state.LanguageReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      // ChangeLanguage: bindActionCreators(
      //   LanguageActions.ChangeLanguage,
      //   dispatch
      // )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
