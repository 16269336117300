import React, { Component } from 'react'

import ScrollContainer from 'react-indiana-drag-scroll'

export default class ScrollContainerOne extends Component {
  render () {
    return (
      <ScrollContainer
        className='scroll-container FastButtons'
        ignoreElements={'*'}
        horizontal={false}
        nativeMobileScroll={true}
        style={{
          height: this.props.height,
          width: this.props.width,
          backgroundColor: 'white',
          overflowY: 'scroll',
          overflowX: 'none'
        }}
      >
        {this.props.children}
      </ScrollContainer>
    )
  }
}

ScrollContainerOne.defaultProps = {
  height: '100vh',
  width: '100%'
}
