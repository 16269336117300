import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'

import { Notifications, NotificationsForm } from '../../views/Notifications'

export default class NotificationsRoute extends Component {
  render () {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Notifications')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Notifications />} />
        <Route
          path={
            releatedRoutes?.create ||
            releatedRoutes?.update ||
            releatedRoutes?.delete
          }
          exact
          element={<NotificationsForm />}
        />
      </Routes>
    )
  }
}
