import { Request } from '../../components/funcs/BASES/Request'

import {    
  //******* SUCESSS **//
  GETALL_SUCCESS,
  GETONE_SUCCESS,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  DELETE_SUCCESS,
  
  
  GETANTRENMANS_SUCCESS,
  GET_DONE_EXECISES_SUCCESS,
  RECORD_DONE_EXERCISES_SUCCESS,
  GET_MY_PROFILE_INFOS_SUCCESS,
  SET_FCM_TOKEN_SUCCESS,
  GET_MY_EGZERSIZ_COUNT_SUCCESS,
  ADD_DAILY_WATER_SUCCESS,
  GET_DAILY_WATER_SUCCESS,
  RESET_USER_PASSWORD,
  GET_MY_NOTIFY_CONFS_SUCCESS,
  SET_MY_NOTIFY_CONFS_SUCCESS,
  GET_USER_PROGRAMS_SUCCESS,

  ASSIGN_PROGRAM_TO_USER,
  UNASSIGN_PROGRAM_TO_USER,
  
  //******* FULL RESET **//
  FULL_RESET
} from '../types/Users'

//******* SUCESSS **//


export const GetAllSuccess = data => {
  return {
    type: GETALL_SUCCESS,
    payload: data
  }
}


export const GetOneSuccess = data => {
  return {
    type: GETONE_SUCCESS,
    payload: data
  }
}

export const CreateSuccess = data => {
  return {
    type: CREATE_SUCCESS,
    payload: data
  }
}

export const UpdateSuccess = data => {
  return {
    type: UPDATE_SUCCESS,
    payload: data
  }
}

export const DeleteSuccess = data => {
  return {
    type: DELETE_SUCCESS,
    payload: data
  }
}



export const GetAntrenmansSuccess = data => {
  return {
    type: GETANTRENMANS_SUCCESS,
    payload: data
  }
}

export const RecordDoneExercisesSuccess = data => {
  return {
    type: RECORD_DONE_EXERCISES_SUCCESS,
    payload: data
  }
}

export const GetDoneExercisesSuccess = data => {
  return {
    type: GET_DONE_EXECISES_SUCCESS,
    payload: data
  }
}

export const GetMyProfileInfosSuccess = data => {
  return {
    type: GET_MY_PROFILE_INFOS_SUCCESS,
    payload: data
  }
}

export const SetFcmTokenSuccess = data => {
  return {
    type: SET_FCM_TOKEN_SUCCESS,
    payload: data
  }
}

export const GetMyEgzersizCountSuccess = data => {
  return {
    type: GET_MY_EGZERSIZ_COUNT_SUCCESS,
    payload: data
  }
}

export const AddDailyWaterSuccess = data => {
  return {
    type: ADD_DAILY_WATER_SUCCESS,
    payload: data
  }
}

export const GetDailyWaterSuccess = data => {
  return {
    type: GET_DAILY_WATER_SUCCESS,
    payload: data
  }
}

export const ResetUserPasswordSuccess = data => {
  return {
    type: RESET_USER_PASSWORD,
    payload: data
  }
}

export const GetMyNotifyConfsSuccess = data => {
  return {
    type: GET_MY_NOTIFY_CONFS_SUCCESS,
    payload: data
  }
}

export const SetMyNotifyConfsSuccess = data => {
  return {
    type: SET_MY_NOTIFY_CONFS_SUCCESS,
    payload: data
  }
}

export const GetUserProgramsSuccess = data => {
  return {
    type: GET_USER_PROGRAMS_SUCCESS,
    payload: data
  }
}



export const AssignProgramToUserSuccess = data => {
  return {
    type: ASSIGN_PROGRAM_TO_USER,
    payload: data
  }
}

export const UnAssignProgramToUserSuccess = data => {
  return {
    type: UNASSIGN_PROGRAM_TO_USER,
    payload: data
  }
}


 


//******* FULL RESET **//
export const FullReset = data => {
  return {
    type: FULL_RESET,
    payload: data
  }
}





export const GetAll = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'GET',
    '/Auth/Users',
    Data,
    data => {
      dispatch(GetAllSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}



export const GetOne = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'GET',
    '/Auth/Users',
    Data,
    data => {
      dispatch(GetOneSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}



export const Create = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'POST',
    '/Users',
    Data,
    data => {
      dispatch(CreateSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}



// getAllUsers
export const Update = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'PUT',
    '/Auth/Users',
    Data,
    data => {
      dispatch(UpdateSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}


// getAllUsers
export const Delete = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'DELETE',
    '/Auth/Users',
    Data,
    data => {
      dispatch(DeleteSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}





// getAllUsers
export const GetAntrenmans = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'GET',
    '/Auth/Users/getUserAntrenmans',
    Data,
    data => {
      dispatch(GetAntrenmansSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}



// getAllUsers
export const RecordDoneExercises = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'POST',
    '/Auth/Users/recordDoneExcercises',
    Data,
    data => {
      dispatch(RecordDoneExercisesSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}



// getAllUsers
export const GetDoneExercises = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'GET',
    '/Auth/Users/getDoneExercises',
    Data,
    data => {
      dispatch(GetDoneExercisesSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}




// getAllUsers
export const GetMyProfileInfos = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'GET',
    '/Auth/Users/getMyProfileInfos',
    Data,
    data => {
      dispatch(GetMyProfileInfosSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}




// getAllUsers
export const SetFcmToken = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'POST',
    '/Auth/Users/SetUserFcmToken',
    Data,
    data => {
      dispatch(SetFcmTokenSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}










export const GetMyEgzersizCount = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'POST',
    '/Auth/Users/GetMyEgzersizCount',
    Data,
    data => {
      dispatch(GetMyEgzersizCountSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}

export const AddDailyWater = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  console.log(Data)
  Request(
    'POST',
    '/Auth/Users/AddGunlukSuTuketim',
    Data,
    data => {
      dispatch(AddDailyWaterSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}

export const GetDailyWater = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'GET',
    '/Auth/Users/GetDailyWaterTotals',
    Data,
    data => {
      dispatch(GetDailyWaterSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}

export const ResetUserPassword = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'POST',
    '/Users/ResetUserPassword',
    Data,
    data => {
      dispatch(ResetUserPasswordSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}

export const GetMyNotifyConfs = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'GET',
    '/Auth/Users/GetMyNotifyConfigs',
    Data,
    data => {
      dispatch(GetMyNotifyConfsSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}

export const SetMyNotifyConfs = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'POST',
    '/Auth/Users/SetMyNotifyConfigs',
    Data,
    data => {
      dispatch(SetMyNotifyConfsSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}



export const GetUserPrograms = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'GET',
    '/Auth/Users/GetUserAssignedPrograms',
    Data,
    data => {
      dispatch(GetUserProgramsSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}




export const AssignProgramToUser = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'POST',
    '/Auth/Users/assignAProgramToUser2',
    Data,
    data => {
      dispatch(AssignProgramToUserSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}




 
export const UnAssignProgramToUser = (
  Data,
  before,
  after,
  onSuccess,
  onError
) => dispatch => {
  Request(
    'POST',
    '/Auth/Users/unAssignProgramToUser',
    Data,
    data => {
      dispatch(UnAssignProgramToUserSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}


 

 