import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import { withRouter } from '../../funcs/BASES'
import { GridBlock } from '../../lib/grid'
import { AnimateOne } from '../../lib/animation'

import { FaEject } from 'react-icons/fa'

const styles = theme => ({
  root: {
    backgroundColor: 'rgba(128, 2, 255, 0.4)', //'#e0e0e0',
    // width: '100%',
    height: 35,
    maxHeight: 35,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    backgroundImage:'linear-gradient(to right, black , white);'
  },
  iconArea: {
    backgroundColor: 'black',
    paddingLeft: 10,
    paddingRight: 10,
    height: 35,
    maxHeight: 35,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginRight: 5
  },
  rightIconTotalArea: {
    display: 'flex',
    flexDirection: 'row'
  }
})

class FormSubHeader extends Component {
  render () {
    const { classes } = this.props   
    return (
      <GridBlock>
        <AnimateOne delay={0} animation={'fadeIn'} >
        <GridBlock justify={'space-between'} className={classes.root}>
     
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ backgroundColor: '#8002ff', width: 10, height: 35 }} />
          <div>
         
              <div className={classes.iconArea}>
                <AnimateOne >
                  <div style={{color:"white", fontSize: 14, fontWeight: 500, textTransform:'uppercase'}} > {this.props.title} </div>
                </AnimateOne>
              </div>
        
          </div>
        </div>
        <div className={classes.rightIconTotalArea}></div>
        </GridBlock>
        </AnimateOne>
      </GridBlock>
    )
  }
}

FormSubHeader.defaultProps = {
  date: '...',
  icon: ''
}

FormSubHeader.propTypes = {
  title: PropTypes.string.isRequired
}

export default withRouter(withStyles(styles)(FormSubHeader))
