

export default {

    isAuthenticated: false,
    jwtDecodedToken: {},
    jwtToken: '',

    messages: [],
    CustomID: Math.random()

}