
  const styles = theme => ({
    root: {
      height: '100vh'
    },
    image: {
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[5000] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '100px 200px 400px 100px;',
      left: 200
    },
    video: {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[5000] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '0 0 0 400px;',
      left: 200
    },
    logoImage:{
      objectFit: "contain",
      width:120
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  });



  export default styles;