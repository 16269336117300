import en from './en.json'
import de from './de.json'
import tr from './tr.json'
import us from './us.json'

import { getReduceState } from '../components/funcs/BASES/getStateOfRed'

const languages = {
  TR: tr,
  GB: en,
  US: us,
  EN: en,
  DE: de
}

function getTranslatation (text, categoryName) {
  try {
    if (categoryName == null) {
      var selectedLanguage = String( getReduceState('LanguageReducers').languageID)
      return languages[selectedLanguage][String(text)]
    } else {
      var selectedLanguage = String(getReduceState('LanguageReducers').languageID)
      return languages[selectedLanguage][categoryName][String(text)]
    }
  } catch (err) {
    console.log(err)
    return ''
  }
}



export { getTranslatation }
