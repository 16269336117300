import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit, FaEnvelope } from 'react-icons/fa'
import notifyTypes from '../../../Consts/notificationTypes.json'
import Moment from 'react-moment'

export const Cols = (onRowClickUpdate, onRowClickedDelete, SendNotification) => {
  return [
    {
      name: 'Tür',
      selector: 'type',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {notifyTypes.find(nn => nn.value == row.type)?.text}
        </div>
      )
    },

    {
      name: 'Tarih',
      selector: 'createdAt',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {<Moment date={row.createdAt} format='YYYY-MM-DD HH:mm' />}
        </div>
      )
    },

    {
      name: 'İletildi',
      selector: 'iletilen',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {row.iletilen} Kullanıcı
        </div>
      )
    },

    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.notifications_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEnvelope size={30} />}
          onClick={() => SendNotification(row.notifications_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
