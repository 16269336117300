import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'

export default class Messages extends Component {
  render () {
    return (
      <ToastContainer
        // position='bottom-right'
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />
    )
  }
}
