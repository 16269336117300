import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../../../funcs/BASES/withRouter'
import { IconButton } from '../../../lib/button'
import { getTranslatation } from '../../../../locales/translate'
import { DynamicFaIcon } from '../../../lib/icons'


import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu
} from 'react-pro-sidebar'

import './custom.scss'

import * as AuthActions from '../../../../redux/actions/Auth'
import EndPoints from '../../../Consts/endPoints.json'

const styles = theme => ({
  welcomeText: {
    padding: '24px',
    // textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: '1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  topIconedArea: {
    padding: '24px',
    alignSelf: 'center',
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }
})

class LeftMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapsed: true
    }
  }

  componentDidMount () {
    console.log(this.props.router)
  }

  goPlease = point => {
    this.props.router.navigate(point)
  }

  logout = () => {
    this.props.actions.Logout()
  }

  render () {
    const { classes } = this.props
    const { name, lastname } = this.props.AuthReducersData.jwtDecodedToken
    return (
      <ProSidebar
        collapsed={this.state.collapsed}
        // image={require('../../../asserts/images/menuBackOne.png')}
        image={getTranslatation('menuImage', 'ENV')}
      >
        <SidebarHeader>
          <SidebarHeader>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {!this.state.collapsed && (
                <div className={classes.welcomeText}>
                  {name + ' ' + lastname}, Hoşgeldiniz.
                </div>
              )}

              <div className={classes.topIconedArea}>
                <IconButton
                  icon={
                    !this.state.collapsed ? (
                      <DynamicFaIcon name={'FaAngleLeft'} />
                    ) : (
                      <DynamicFaIcon name={'FaAngleRight'} />
                    )
                  }
                  onClick={() => {
                    this.setState({ collapsed: !this.state.collapsed })
                  }}
                />
              </div>
            </div>
          </SidebarHeader>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape='square'>
            {EndPoints.filter(endpoint => endpoint.showOnMenu == true).map(
              endpoint => {
                return (
                  <MenuItem
                    icon={<DynamicFaIcon name={endpoint.icon} />}
                    onClick={() => {
                      this.goPlease(endpoint.routes.get)
                    }}
                  >
                    {endpoint.menutext}
                  </MenuItem>
                )
              }
            )}
          </Menu>
        </SidebarContent>

        <SidebarFooter>
          <Menu iconShape='square'>
            <MenuItem icon={  <DynamicFaIcon name={'FaPowerOff'} />  } onClick={this.logout}>
              Çıkış Yap
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    )
  }
}

//connect componenet with redux store
function mapStateToProps (state) {
  return {
    AuthReducersData: state.AuthReducers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      Logout: bindActionCreators(AuthActions.Logout, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeftMenu))
)
