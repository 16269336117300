import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'
import Moment from 'react-moment';

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Kullanıcı',
      selector: 'fullname',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.fullname}</div>
    },

    {
      name: 'Fark',
      selector: '',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>  <Moment diff={row.fileOne.createdAt}  unit="days">{row.fileTwo.createdAt}</Moment> </div>
    },

    {
      name: 'Sıralama',
      selector: '',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>  {row.degisimlerim_order} </div>
    },
    

    {
      name: 'Yayın izni',
      selector: 'canPublish',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}> {row?.canPublish == 1 ? 'VAR': 'YOK'} </div>
    },

    

    {
      name: 'Yayında',
      selector: 'onPublish',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>  {row?.onPublish == 1 ? 'YAYINDA': 'YAYINDA DEĞİL'}</div>
    },
    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.degisimlerim_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.degisimlerim_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
