import * as Types from '../types/Alldetails'
import Initials from '../initials/Alldetails'

export default function Reducers (state = Initials, action) {
  switch (action.type) {
    //*********************************************** */  SUCCESS

    case Types.GET_ALL_DETAILS_SUCCESS:
      return {
        ...state,
        AllDetails: action.payload,
        messages: [],
        CustomID: Math.random(),
      }


    default:
      return state
  }
}
