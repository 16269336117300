import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'

import { Payments, PaymentsForm } from '../../views/Payments'

export default class ProgramsRoute extends Component {
  render () {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Payments')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Payments />} />
        <Route path={releatedRoutes?.create} exact element={<PaymentsForm />} />
        <Route path={releatedRoutes?.update} exact element={<PaymentsForm />} />
        <Route path={releatedRoutes?.delete} exact element={<PaymentsForm />} />
      </Routes>
    )
  }
}
