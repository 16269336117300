export default {
  AllDetails: {
    CountryNames: [],
    CountryCodes: [],
    CountryPhoneCodes: [],
    Languages: [],
    Roles: [],
    Cities: [],
    States: [],

    VucutTypes: [],
    ProgramTargets: [],
    FitnessLevels: [],
    BodyTargetOptions: [],
    Ekipmanlar: [],

    Hareketler: [],
    Egzersizler: [],
    Antrenmanlar: [],
    Programlar: [],
    Beslenmeler: [],

    ProgramCategory: [],
    BeslenmeCategory: [],
  },

  messages: [],
  CustomID: Math.random(),
}
