import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'

import { Challange, ChallangeForm } from '../../views/Challange'

export default class ChangesRoute extends Component {
  render() {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Challanges')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Challange />} />
        <Route path={releatedRoutes?.create} exact element={<ChallangeForm />} />
        <Route path={releatedRoutes?.update} exact element={<ChallangeForm />} />
        <Route path={releatedRoutes?.delete} exact element={<ChallangeForm />} />
      </Routes>
    )
  }
}
