import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'




export const Cols = ( onRowClickedDelete , Antrenmanlar) => {
  return [
 

    {
      name: 'Antrenman',
      selector: 'antrenman_antrenman_id',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>

 
          {
            Antrenmanlar?.find(
              aa => aa.value == row.antrenman_antrenman_id
            )?.text
          }
        </div>
      )
    },

    {
      name: 'Sıra',
      selector: 'orderDay',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.orderDay}</div>
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.uuid)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
