import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'

import { Users, UsersForm } from '../../views/Users'

export default class UsersRoute extends Component {
  render () {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Users')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Users />} />
        <Route path={releatedRoutes?.create} exact element={<UsersForm />} />
        <Route path={releatedRoutes?.update} exact element={<UsersForm />} />
        <Route path={releatedRoutes?.delete} exact element={<UsersForm />} />
      </Routes>
    )
  }
}
