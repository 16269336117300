import axios from 'axios'
import { showMultipleMessages } from '../../funcs/BASES/showErrorMultiMessages'
import { setAxiosConfigs } from '../../../configs/axiosCons'

import { Store } from '../../../redux/configureStore'
import * as AuthActions from '../../../redux/actions/Auth'

function getErrorMessages (errorResponse) {
  try {
    return JSON.parse(errorResponse.response).userMessages
  } catch (err) {
    return errorResponse
  }
}

function doDirectLogout () {
  localStorage.clear()
  Store.dispatch(AuthActions.Logout())
}

function doOnError (err) {
  if (err.request?.status == 401) {
    doDirectLogout()
  } else if (err.request?.status == 403) {
    try {
      var message = JSON.parse(err.request?._response).developerMessages[0]
        .message
      showMultipleMessages(message + ' - for dev message', 'warning')
    } catch (err) {
      showMultipleMessages(getErrorMessages(err.request), 'warning')
    }
  } else {
    showMultipleMessages(getErrorMessages(err.request), 'error')
  }
}

function doOnBody (
  resp,
  successFroReducer,
  faultForReducer,
  onSuccess,
  onError,
  after,
  showMessageOnSuccess,
  showMessageOnError
) {


  try {
    var jsonResponse = JSON.parse(resp)
  } catch (err) {
    var jsonResponse = resp
  }

  if (jsonResponse?.data?.success != null) {
    // axiosta response body bu sekilde geliyor fethte farklı
    var responseBody = jsonResponse.data
  } else {
    var responseBody = jsonResponse
  }

  const { success, developerMessages, userMessages, data } = responseBody

  if (success) {
    successFroReducer(data)
    onSuccess(data)


    if (showMessageOnSuccess) {
      showMultipleMessages(userMessages, 'success')
    }
  } else {
    faultForReducer(data)
    onError(data)

    if (showMessageOnError) {
      showMultipleMessages(userMessages, 'warning')
    }
  }
  after()
}

export const Request = (
  method,
  Url,
  Data,
  successFroReducer,
  faultForReducer,

  before,
  after,
  onSuccess,
  onError,

  showMessageOnError,
  showMessageOnSuccess,

  forStorage
) => {
  setAxiosConfigs(forStorage?.type)
  before()

  if (method == 'POST') {
    //********************************************** POST METHOD *************/
    if (forStorage?.type == 'storage') {
      fetch(process.env.REACT_APP_STORAGE_URL + Url, forStorage.config)
        .then(response => response.text())
        .then(resp => {
          doOnBody(
            resp,
            successFroReducer,
            faultForReducer,
            onSuccess,
            onError,
            after,
            showMessageOnSuccess,
            showMessageOnError
          )
        })
        .catch(err => {
          doOnError(err)
          after()
        })
    } else {
      axios
        .post(Url, Data)
        .then(resp => {
          doOnBody(
            resp,
            successFroReducer,
            faultForReducer,
            onSuccess,
            onError,
            after,
            showMessageOnSuccess,
            showMessageOnError
          )
        })
        .catch(err => {
          doOnError(err)
          after()
        })
    }
  } else if (method == 'GET') {
    //********************************************** GET METHOD *************/
    if (Data != null) {
      var lastUrl = Url + '/' + Data
    } else {
      var lastUrl = Url
    }

    axios
      .get(lastUrl, Data)
      .then(resp => {
        doOnBody(
          resp,
          successFroReducer,
          faultForReducer,
          onSuccess,
          onError,
          after,
          showMessageOnSuccess,
          showMessageOnError
        )
      })
      .catch(err => {
        console.log(err, 'errerrerrerr')
        doOnError(err)
        after()
      })
  } else if (method == 'DELETE') {
    //********************************************** DELETE METHOD *************/
    var lastUrl = Url + '/' + Data
    axios
      .delete(lastUrl, Data)
      .then(resp => {
        doOnBody(
          resp,
          successFroReducer,
          faultForReducer,
          onSuccess,
          onError,
          after,
          showMessageOnSuccess,
          showMessageOnError
        )
      })
      .catch(err => {
        doOnError(err)
        after()
      })
  } else if (method == 'PUT') {
    //********************************************** PUT METHOD *************/
    axios
      .put(Url, Data)
      .then(resp => {
        doOnBody(
          resp,
          successFroReducer,
          faultForReducer,
          onSuccess,
          onError,
          after,
          showMessageOnSuccess,
          showMessageOnError
        )
      })
      .catch(err => {
        doOnError(err)
        after()
      })
  }
}
