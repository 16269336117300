import React, { Component } from 'react'
import Routes from '../src/routes'
import Messages from '../src/components/features/NotifyMessages/Messages'



export default class App extends Component {
  render () {
    return (
      <div>
        <Messages/>
        <Routes />
      </div>
    )
  }
}
