import React, { Component } from 'react'
import PropTypes from 'prop-types'

import InputOne from './inputOne'
import IconButton from '../button/IconButton'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

export default class InputPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  togglePassword = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    return (
      <InputOne
        type={this.state.isOpen ? 'text' : 'password'}
        color={this.props.color}
        variant={this.props.variant}
        margin={this.props.margin}
        required
        fullWidth
        xs={this.props.xs}
        sm={this.props.sm}
        lg={this.props.lg}
        id={this.props.id}
        label={this.props.label}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        // error={this.state.mailOrPhone_ERROR.length > 0}
        // helperText={getTranslatation(this.state.mailOrPhone_ERROR)}
        endAdornment={
          <IconButton
            onClick={this.togglePassword}
            icon={
              this.state.isOpen ? <VisibilityOffIcon /> : <VisibilityIcon />
            }
          />
        }
      />
    )
  }
}

InputPassword.defaultProps = {
  label: '...',
  placeholder: '...',
  helperText: '...',
  xs: 12,
  sm: 12,
  lg: 12,

  error: false,
  maskPlaceholder: '_',
  mask: null
}

InputPassword.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string
}
