import { Request } from '../../components/funcs/BASES/Request'


import {
  GET_ALL_DETAILS_SUCCESS
} from '../types/Alldetails'

//******* SUCESSS **//
export const GetAllDetailsSuccess = data => {
  return {
    type: GET_ALL_DETAILS_SUCCESS,
    payload: data
  }
}


//*********************************************************************************** */

// GetAllDetails 
export const GetAllDetails = (Data, before, after, onSuccess, onError) => dispatch => {

  Request(
    'GET',
    '/AllDetails',
    Data,
    (data)=> { dispatch(GetAllDetailsSuccess(data)) }, //success reduce
    ()=>{}, // error reduce


    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}
