//*********************   TYPES    ****************************** */
var nameSpace = '___Users'

export const GETALL_SUCCESS = "GETALL_SUCCESS" + nameSpace;
export const GETONE_SUCCESS = "GETONE_SUCCESS" + nameSpace;
export const CREATE_SUCCESS = "CREATE_SUCCESS" + nameSpace;
export const UPDATE_SUCCESS = "UPDATE_SUCCESS" + nameSpace;
export const DELETE_SUCCESS = "DELETE_SUCCESS" + nameSpace;


export const GETANTRENMANS_SUCCESS = "GETANTRENMANS_SUCCESS" + nameSpace;
export const GET_DONE_EXECISES_SUCCESS = "GET_DONE_EXECISES_SUCCESS" + nameSpace;
export const RECORD_DONE_EXERCISES_SUCCESS = "RECORD_DONE_EXERCISES_SUCCESS" + nameSpace;
export const GET_MY_PROFILE_INFOS_SUCCESS = "GET_MY_PROFILE_INFOS_SUCCESS" + nameSpace;
export const SET_FCM_TOKEN_SUCCESS = "SET_FCM_TOKEN_SUCCESS" + nameSpace;
export const GET_MY_EGZERSIZ_COUNT_SUCCESS = "GET_MY_EGZERSIZ_COUNT_SUCCESS" + nameSpace;
export const ADD_DAILY_WATER_SUCCESS = "ADD_DAILY_WATER_SUCCESS" + nameSpace;
export const GET_DAILY_WATER_SUCCESS = "GET_DAILY_WATER_SUCCESS" + nameSpace;
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD' + nameSpace;
export const GET_MY_NOTIFY_CONFS_SUCCESS = 'GET_MY_NOTIFY_CONFS_SUCCESS' + nameSpace;
export const SET_MY_NOTIFY_CONFS_SUCCESS = 'SET_MY_NOTIFY_CONFS_SUCCESS' + nameSpace;
export const GET_USER_PROGRAMS_SUCCESS = 'GET_USER_PROGRAMS_SUCCESS' + nameSpace;


export const ASSIGN_PROGRAM_TO_USER = 'ASSIGN_PROGRAM_TO_USER' + nameSpace;
export const UNASSIGN_PROGRAM_TO_USER = 'UNASSIGN_PROGRAM_TO_USER' + nameSpace;



//******* FULL RESET **//
export const FULL_RESET = "FULL_RESET" + nameSpace;