import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { SelectOne } from '../../../../lib/select'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'

import { getReduceState } from '../../../../funcs/BASES/getStateOfRed'

export default class AddModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ekipmanlar_ekipmanlar_id: '',
      uuid: ''
    }
  }

  submit = () => {
    this.setState(
      {
        uuid: uuidv4()
      },
      () => {
        this.props.fakeThis.setState(
          {
            datas: [...this.props.fakeState.datas, this.state],
            modalIsOpen: false
          },
          () => {
            this.setState({
              ekipmanlar_ekipmanlar_id: '',
              uuid: ''
            })
          }
        )
      }
    )
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const AlldetailsReducers = getReduceState('AlldetailsReducers')
    return (
      <ModalOne
        open={this.props.open}
        bgDropColor={'rgba(128, 2, 255, 0.2)'}
        fullScreen={true}
      >
        <FormLayout
          naviIcon={<FaWpforms size={30} color={'white'} />}
          recordIcon={<FaSave size={30} color={'white'} />}
          recordAction={this.submit}
          // filesIcon={<FaFolder size={30} color={'white'} />}
          activeBack={() => {
            this.props.fakeThis.setState({ modalIsOpen: false })
          }}
          loading={false}
        >
          <GridBlock
            title={getTranslatation('antrenmanaegzersizatayın', 'WORKOUT')}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
       

            <SelectOne
              placeholder={getTranslatation('ekipmanlar', 'WORKOUT')}
              label={getTranslatation('ekipmanlar', 'WORKOUT')}
              helperText={getTranslatation('ekipmanlarHelper', 'WORKOUT')}
              xs={12}
              sm={12}
              lg={12}
              value={this.state.ekipmanlar_ekipmanlar_id}
              onChange={this.setVal}
              name='ekipmanlar_ekipmanlar_id'
              id='ekipmanlar_ekipmanlar_id'
              options={AlldetailsReducers?.AllDetails?.Ekipmanlar}
            />
          </GridBlock>
        </FormLayout>
      </ModalOne>
    )
  }
}
