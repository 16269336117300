import * as Types from '../types/Users'
import Initials from '../initials/Users'




// GET_DONE_EXECISES_SUCCESS,
// RECORD_DONE_EXERCISES_SUCCESS,
// GET_MY_PROFILE_INFOS_SUCCESS,
// SET_FCM_TOKEN_SUCCESS,
// GET_MY_EGZERSIZ_COUNT_SUCCESS,

// RESET_USER_PASSWORD,



export default function Reducers (state = Initials, action) {
  switch (action.type) {
    //*********************************************** */  SUCCESS

    case Types.GETALL_SUCCESS:
      return {
        ...state,
        All: action.payload,
        messages: [],
        CustomID: Math.random()
      }

      case Types.GETONE_SUCCESS:
        return {
          ...state,
          OneUser: action.payload,
          messages: [],
          CustomID: Math.random(),
        }

    case Types.CREATE_SUCCESS:
      return {
        ...state,
        All: state.All.concat(action.payload),
        messages: [],
        CustomID: Math.random()
      }

    case Types.UPDATE_SUCCESS:
      var foundedObj =  state.All.find(aa => aa.users_id == action.payload?.users_id)
      foundedObj = action.payload
      return {
        ...state,
        All: state.All,
        messages: [],
        CustomID: Math.random()
      }

    case Types.DELETE_SUCCESS:
      var clearedDatas = state.All.filter(aa => aa.users_id != action.payload?.users_id)
      return {
        ...state,
        All: clearedDatas,
        messages: [],
        CustomID: Math.random()
      }


    case Types.GETANTRENMANS_SUCCESS:
      return {
        ...state,
        Antrenmans: action.payload,
        messages: [],
        CustomID: Math.random(),
      }

    //*********************************************** */  WATERS

    case Types.ADD_DAILY_WATER_SUCCESS:
      return {
        ...state,
        DailyWater: action.payload,
        messages: [],
        CustomID: Math.random(),
      }

    case Types.GET_DAILY_WATER_SUCCESS:
      return {
        ...state,
        DailyWater: action.payload,
        messages: [],
        CustomID: Math.random(),
      }

    //*********************************************** */  NOTIFY CONFS

    case Types.GET_MY_NOTIFY_CONFS_SUCCESS:
      return {
        ...state,
        UserConfs: action.payload,
        messages: [],
        CustomID: Math.random(),
      }

    case Types.SET_MY_NOTIFY_CONFS_SUCCESS:
      return {
        ...state,
        UserConfs: action.payload,
        messages: [],
        CustomID: Math.random(),
      }

      
    case Types.GET_USER_PROGRAMS_SUCCESS:
      return {
        ...state,
        Programs: action.payload,
        messages: [],
        CustomID: Math.random(),
      }

    //*********************************************** */  FULL RESETS
    case Types.FULL_RESET:
      return {
        ...state,

        OneUser: {},

        messages: [],
        CustomID: Math.random(),
      }

    default:
      return state
  }
}
