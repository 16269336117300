import { ToastContainer, toast } from 'react-toastify'

const toastConfig = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

function showMessage (message, type) {
  if (type == 'default') {
    toast(message.toString(), toastConfig)
  } else if (type == 'warning') {
    toast.warning(message.toString(), toastConfig)
  } else if (type == 'error') {
    toast.error(message.toString(), toastConfig)
  } else {
    toast.success(message.toString(), toastConfig)
  }
}

export const showMultipleMessages = (messagesNew, type = 'default') => {
  if (messagesNew != null) {
    try {

      messagesNew.forEach((messgeObject, index) => {
        setTimeout(() => {
          var messageType = (messgeObject?.success != null ?  (messgeObject?.success == true ? 'success' : 'error') : type )
          showMessage(Object.values(messgeObject), messageType)
        }, 100 * index)
      })
    } catch (err) {
 
      try {
        var messageType = (messagesNew?.success != null ?  (messagesNew?.success == true ? 'success' : 'error') : type )
        showMessage(messagesNew, messageType)
      } catch (err2) {
  
        showMessage(err2, 'error')
      }
    }
  }
}
