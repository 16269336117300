import { combineReducers } from 'redux'

import {
  Auth as AuthReducers,
  Users as UsersReducers,
  Alldetails as AlldetailsReducers,
  Antrenman as AntrenmanReducers,
  Program as ProgramReducers,
  Beslenme as BeslenmeReducers,
  Degerlendirmeler as DegerlendirmelerReducers,
  Degisimler as DegisimlerReducers,
  Notification as NotificationReducers,
  Hareketler as HareketlerReducers,
  Language as LanguageReducers,
  Egzersizler as EgzersizlerReducers,
  Challange as ChallangeReducers,
  Payments as PaymentsReducers
} from './reducers'



const rootreducer = combineReducers({
  AuthReducers,
  AlldetailsReducers,
  UsersReducers,
  AntrenmanReducers,
  ProgramReducers,
  BeslenmeReducers,
  DegerlendirmelerReducers,
  DegisimlerReducers,
  NotificationReducers,
  HareketlerReducers,
  LanguageReducers,
  EgzersizlerReducers,
  ChallangeReducers,
  PaymentsReducers,
})

export default rootreducer
