import * as LanguageTypes from '../types/Language'
import LanguageInitials from '../initials/Language'

export default function messageReducers (state = LanguageInitials, action) {
  switch (action.type) {
    case LanguageTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        languageID: action.payload,
        CustomID: Math.random()
      }

    default:
      return state
  }
}
