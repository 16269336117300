import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'

import { Appreciations, AppreciationsForm } from '../../views/Appreciations'

export default class AppreciationsRoute extends Component {
  render () {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Appreciations')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Appreciations />} />
        <Route path={releatedRoutes?.create} exact element={<AppreciationsForm />} />
        <Route path={releatedRoutes?.update} exact element={<AppreciationsForm />} />
        <Route path={releatedRoutes?.delete} exact element={<AppreciationsForm />} />
      </Routes>
    )
  }
}
