import React from 'react'
import Lottie from 'react-lottie'
import * as animationData from '../../../asserts/lotties/77251-message-sent.json'
import Box from '@material-ui/core/Box'

import { getTranslatation } from '../../../locales/translate'

export default class LottieControl extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isStopped: false, isPaused: false }
  }

  render () {
    const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: this.props.animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={this.props.height}
          width={this.props.width}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        {/* <Box style={{ textAlign: 'center', marginLeft: 10, marginRight: 10,marginBottom:10 }}>
          {getTranslatation('deaR')} 
          <p style={{fontWeight:'900'}}  > {this.props.name + ' ' + this.props.lastname+ ' '} </p>
          {getTranslatation('sentMessage')}
        </Box> */}
      </div>
    )
  }
}

LottieControl.defaultProps = {
  animationData: animationData,
  height:300,
  width:300

}
