import React, { Component } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default class modalOne extends Component {
  render () {
    return (
      <Dialog
        BackdropProps={{ style: { backgroundColor: this.props.bgDropColor } }}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
        fullScreen={this.props.fullScreen}
        fullWidth={this.props.fullWidth}
        maxWidth={this.props.maxWidth}
      >
        {/* <DialogTitle id='responsive-dialog-title' style={{textAlign:'center'}}>
          {"Please select your language."}
        </DialogTitle> */}
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflow: 'hidden',
            marginTop: 20
          }}
        >
          {this.props.children}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={()=>{} }>
            Disagree
          </Button>
          <Button autoFocus onClick={()=>{} }>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    )
  }
}

modalOne.defaultProps = {
  fullScreen: false,
  bgDropColor: 'gray',
  onClose: () => {},
  fullWidth: true,
  maxWidth: 'lg'
}
