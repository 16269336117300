import React, { Component } from 'react'


import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

export default class IconButtonCs extends Component {
  render () {
    return (
      <Stack direction='row' alignItems='center' spacing={1}>
        <IconButton
          style={{ zIndex: 900 }}
          disabled={this.props.disabled}
          aria-label='back'
          size='large'
          onClick={this.props.onClick}
        >
          {this.props.icon}
        </IconButton>
      </Stack>
    )
  }
}
