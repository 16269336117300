import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

class SelectOne extends Component {
  render () {
    return (
      <Grid
        item
        xs={this.props.xs}
        sm={this.props.sm}
        lg={this.props.lg}
        style={this.props.style}
      >
        <FormControl variant='filled' sx={{ width: '100%' }}>
          {this.props.label != null && (
            <InputLabel id='demo-simple-select-filled-label'>
              {this.props.label}
            </InputLabel>
          )}

          <Select
            multiple={this.props.multiple}
            disabled={this.props.disabled}
            name={this.props.name}
            id={this.props.id}
            value={this.props.value}
            onChange={this.props.onChange}
          >
            <MenuItem disabled value=''>
              <em>{this.props.placeholder}</em>
            </MenuItem>

            {
              typeof this.props.options == 'object' &&
                  this.props.options.map(data => {
                    return (
                      <MenuItem value={data.value} key={data.text} disabled={data?.disabled} >
                        {data.text}
                      </MenuItem>
                    )
                  })
            }
          </Select>

          <FormHelperText>
            {this.props.helperText != null ? this.props.helperText : '...'}
          </FormHelperText>
        </FormControl>
      </Grid>
    )
  }
}

SelectOne.defaultProps = {
  xs: 12,
  sm: 6,
  lg: 4,
  style: { paddingLeft: 5, paddingRight: 5, marginBottom: 5 }
}

SelectOne.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array
}

export default SelectOne
