import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'

export default class AddModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      order: 1,
      desc: '',
      uuid: ''
    }
  }

  submit = () => {
    this.setState(
      {
        uuid: uuidv4()
      },
      () => {
        this.props.fakeThis.setState(
          {
            datas: [...this.props.fakeState.datas, this.state],
            modalIsOpen: false
          },
          () => {
            this.setState({
              order: 1,
              desc: '',
              uuid: ''
            })
          }
        )
      }
    )
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  
  render () {
    return (
      <ModalOne
        open={this.props.open}
        bgDropColor={'rgba(128, 2, 255, 0.2)'}
        fullScreen={true}
      >
        <FormLayout
          naviIcon={<FaWpforms size={30} color={'white'} />}
          recordIcon={<FaSave size={30} color={'white'} />}
          recordAction={this.submit}
          // filesIcon={<FaFolder size={30} color={'white'} />}
          activeBack={() => {
            this.props.fakeThis.setState({ modalIsOpen: false })
          }}
          loading={false}
        >
          <GridBlock
            title={getTranslatation('hareketAciklamaForm', 'ACTIONS')}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
            <InputOne
              type={'number'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='order'
              label={getTranslatation('order', 'ACTIONS')}
              name='order'
              value={this.state.order}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('orderHelper', 'ACTIONS')}
            />

            <InputOne
              multiline={true}
              minRows={4}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='desc'
              label={getTranslatation('desc', 'ACTIONS')}
              name='desc'
              value={this.state.desc}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('descHelper', 'ACTIONS')}
            />
          </GridBlock>
        </FormLayout>
      </ModalOne>
    )
  }
}
