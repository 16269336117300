
export default {



    All: [],
    One: {},

    messages: [],
    CustomID: Math.random(),

}