import React from 'react'
import ReactDOM from 'react-dom'
import { useSpring, animated } from '@react-spring/web'
import './styles.css' // // Icons made by Freepik from www.flaticon.com
import { GridBlock } from '../../grid'
import { AnimateOne } from '../../animation'




const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

export default function Card(allProps) {

  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <GridBlock  container  justify={'flex-end'}  align={'space-between'} style={{ padding:10,marginTop:120,paddingRight: 150   }}>
      <div class="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <AnimateOne>
          <animated.div class="card1" style={{ transform: props.xy.interpolate(trans1), backgroundImage:'url('+ allProps.imageOne + ')' }} />
        </AnimateOne>
        <AnimateOne>
          <animated.div class="card2" style={{ transform: props.xy.interpolate(trans2), backgroundImage:'url('+ allProps.imageTwo + ')' }} />
        </AnimateOne>
        <AnimateOne>
          <animated.div class="card3" style={{ transform: props.xy.interpolate(trans3), backgroundImage:'url('+ allProps.imageThree + ')' }} />
        </AnimateOne>
        <AnimateOne>
          <animated.div class="card4" style={{ transform: props.xy.interpolate(trans4), backgroundImage:'url('+ allProps.imageFour + ')' }} />
        </AnimateOne>
   
      </div>
    </GridBlock>
  )
}


// ReactDOM.render(<Card />, document.getElementById('root'))



