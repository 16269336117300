import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from '../../../components/funcs/BASES'
import queryString from 'query-string'

import { GridBlock } from '../../../components/lib/grid'
import { BasicCardOne } from '../../../components/lib/cards'
import { AnimateOne } from '../../../components/lib/animation'
import Grid from '@mui/material/Grid'
import { getTranslatation } from '../../../locales/translate'

import { LottiePlayerOne } from '../../../components/lib/lottie'
import * as animationData2 from '../../../asserts/lotties/lf30_editor_wxqocwsu.json'
import * as animationData3 from '../../../asserts/lotties/373-streetby-test-loading.json'
import * as animationData4 from '../../../asserts/lotties/68314-starfall.json'

import { FaRegEye, FaRegUser, FaInbox } from 'react-icons/fa'

const styles = theme => ({
  leftComp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  rightComp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[5000]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '0px 200px 400px 0px;',
    left: 200
  }
})

class LoginLottie extends Component {
  render () {
    const { classes } = this.props
    return (
      <Grid
        item
        xs={false}
        sm={false}
        md={false}
        lg={this.props.lg}
        // style={{ backgroundImage: getTranslatation('image2') }}
        className={[classes.image]}
        display={{
          xs: 'none',
          sm: 'none',
          md: 'none',
          lg: 'block',
          display: 'flex'
          // paddingTop: 10,
          // backgroundColor:'#f7f7f7'
        }}
        onClick={() => {
          this.setState({ videoPlayed: true })
        }}
      >
        <AnimateOne animation={'bounceInLeft'}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              backgroundImage: getTranslatation('bgImage', 'LOGIN'),
              backgroundColor: 'wgite',
              height: '100vh'
            }}
            className={[classes.image]}
            onClick={() => {
              this.setState({ videoPlayed: true })
            }}
          >
            {/* <GridBlock style={{  display: 'flex',paddingTop: 60, paddingLeft: 50, paddingRight: 50, justifyContent:'space-evenly', width:'100%', height: 300  }} >
            <BasicCardOne width={190} height={200} backgroundColor={"black"} title={"250 milyondan fazla aylık ziyaret"} icon={<FaRegEye color={'white'}  size={50} />}  /> 
            <BasicCardOne width={190} height={200} backgroundColor={"black"} title={"39 milyondan fazla kayıtlı kullanıcı"} icon={<FaRegUser color={'white'}   size={50} />}   /> 
            <BasicCardOne width={190} height={200} backgroundColor={"black"} title={"77 milyondan fazla ürün çeşidi"}  icon={<FaInbox color={'white'}   size={50} />}  /> 
        </GridBlock> */}

            <LottiePlayerOne
              animationData={animationData4}
              height={'100vh'}
              width={'100%'}
            />
          </Grid>
        </AnimateOne>
      </Grid>
    )
  }
}

LoginLottie.defaultProps = {
  lg: 8
}

export default withRouter(withStyles(styles)(LoginLottie))
