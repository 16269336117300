import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class BasicCardOne extends Component {
  render () {
    return (
      <div
        style={{
          width: this.props.width,
          height: this.props.height,
          backgroundColor: this.props.backgroundColor,
    

          margin: 10,
          borderRadius: 40,
          textAlign: 'center',
       
          //   borderWidth: 1,
          //   borderStyle: "solid",
          //   borderColor:'orange',
        //   boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
          padding: 10,
          paddingTop: 20
        }}
      >
        {this.props.icon}
        <div
          style={{
            fontSize: 24,
            fontFamily: "'Gloria Hallelujah', cursive",
            color:'white',
            fontWeight:'900'
          }}
        >
          {this.props.title}
        </div>
        {this.props.desc}
      </div>
    )
  }
}

BasicCardOne.defaultProps = {
  backgroundColor: 'orange',
  width: 140,
  height: 140
}

BasicCardOne.propTypes = {
  //   value: PropTypes.object,
}
