import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaUser } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'
import UploadOne from '../../components/lib/upload/UploadOne'
import { Programlar } from '../../components/features/Users';



import * as UsersActions from '../../redux/actions/Users'
import * as ProgramActions from '../../redux/actions/Program'

class UsersForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onProcess: false,
      activeSection: 'form', // files

      users_id: '',

      name: '',
      lastname: '',
      pasword: '',
      paswordAgain: '',

      mails_text: '',
      phones_text: '',
      phoneCounryID: '',
      rolesID: '2',

      usedLanguageTag: 'DEFAULT',

      adresCountryID_HOME: '',
      adresStateID_HOME: '',
      adresCityID_HOME: '',
      adress_text_HOME: '',


      programlar: [],
      programOptions: [],

      isActive: true
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Users')?.routes
    this.ref_fileArea = React.createRef()
  }

  componentDidMount() {
    try {
      var id = this.props.router.location.state.id
      this.setState({ users_id: id }, () => {
        this.props.actions.GetOne(
          this.state.users_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => { }
        )

        this.props.actions.GetUserPrograms(
          this.state.users_id,
          () => { },
          () => { },
          data => {
            this.setState({ programlar: data })
          },
          () => { }
        )


        this.props.actions.GetAllPrograms(
          null,
          () => { },
          () => { },
          data => {
            const programOptions = data.map((item) => {
              return { value: item.program_id, text: item.programName + (item.challangeId == null ? '' : ' - CHALLANGE VAR'), challangeId: item.challangeId }
            })
            this.setState({ programOptions })
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    var stateID = this.props.AlldetailsReducers.AllDetails.Cities.find(
      aa => aa.value == data?.adresCityID_HOME
    )?.stateID
    this.setState({
      name: data?.name,
      lastname: data?.lastname,
      pasword: data?.passwordA,
      paswordAgain: data?.passwordA,

      mails_text: data?.mails_text,
      phones_text: data?.phones_text,
      phoneCounryID: data?.phoneCounryID,
      rolesID: data?.roles_id,

      adresCountryID_HOME: data?.adresCountryID_HOME,
      adresStateID_HOME: stateID,
      adresCityID_HOME: data?.adresCityID_HOME,
      adress_text_HOME: data?.adress_text_HOME,


    }, () => {
      this.ref_fileArea.current.state.files = data?.profileImage != null ? [data?.profileImage] : []
    })
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (e.target.name == 'adresCountryID_HOME') {
        this.setState({
          adresStateID_HOME: '',
          adresCityID_HOME: '',
          adress_text_HOME: ''
        })
      }

      if (e.target.name == 'adresStateID_HOME') {
        this.setState({
          adresCityID_HOME: '',
          adress_text_HOME: ''
        })
      }

      if (e.target.name == 'adresCityID_HOME') {
        this.setState({
          adress_text_HOME: ''
        })
      }

      if (e.target.name == 'phones_text') {
        this.setState({
          phones_text: this.state.phones_text?.replace(/\D/g, '')
        })
      }
    })
  }

  render() {
    const userRoutes = endPoints.find(aa => aa.name == 'Users')?.routes
    const CountryPhoneCodes = this.props.AlldetailsReducers.AllDetails
      .CountryPhoneCodes

    //role processes START
    const roleOptions = this.props.AlldetailsReducers.AllDetails?.Roles
    var adminRole = roleOptions.find(aa => aa.text == 'Admin')
    if (adminRole != null) {
      adminRole.disabled = true
    }
    //role processes END

    return (
      <FormLayout
        naviIcon={<FaUser size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.users_id != null && this.state.users_id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(userRoutes?.get)
        }}
        loading={this.state.onProcess}
      >



        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>
          <UploadOne
            ref={this.ref_fileArea}
            innerRef={this.ref_fileArea}
            cascadeID={this.state.users_id}
            showDropAreaWhenFull={true}
            formID={1} // userprofile image
            allowedLength={1}
            xs={12}
            sm={12}
            lg={12}
            _xs={12}
            _sm={12}
            _lg={12}
            _mainContainer
            acceptFiles={'image/jpeg, image/png, image/jpg,'}
            dropLabel={
              <div>
                Kişi resmi yükleyiniz <br /> en fazla 1 Adet{' '}
              </div>
            }
          />
        </GridBlock>


        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>
          <GridBlock title='genel' container={true} xs={12} sm={12} lg={12}>
            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='name'
              label={getTranslatation('name', 'USERS')}
              name='name'
              value={this.state.name}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('nameHelper', 'USERS')}
            />

            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='lastname'
              label={getTranslatation('lastname', 'USERS')}
              name='lastname'
              value={this.state.lastname}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('lastnameHelper', 'USERS')}
            />
            <InputOne
              type={'password'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='pasword'
              label={getTranslatation('pasword', 'USERS')}
              name='pasword'
              value={this.state.pasword}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('paswordHelper', 'USERS')}
            />

            <InputOne
              type={'password'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='paswordAgain'
              label={getTranslatation('paswordAgain', 'USERS')}
              name='paswordAgain'
              value={this.state.paswordAgain}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('paswordAgainHelper', 'USERS')}
            />

            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={4}
              id='mails_text'
              label={getTranslatation('mails_text', 'USERS')}
              name='mails_text'
              value={this.state.mails_text}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('mails_textHelper', 'USERS')}
            />

            <SelectOne
              placeholder={getTranslatation('roleID', 'USERS')}
              label={getTranslatation('roleID', 'USERS')}
              helperText={getTranslatation('roleIDHelper', 'USERS')}
              xs={12}
              sm={6}
              lg={4}
              value={this.state.rolesID}
              onChange={this.setVal}
              name='rolesID'
              id='rolesID'
              options={roleOptions}
            />

            <SelectOne
              placeholder={getTranslatation('usedLanguageTag', 'USERS')}
              label={getTranslatation('usedLanguageTag', 'USERS')}
              helperText={getTranslatation('usedLanguageTagHelper', 'USERS')}
              xs={12}
              sm={6}
              lg={4}
              value={this.state.usedLanguageTag}
              onChange={this.setVal}
              name='usedLanguageTag'
              id='usedLanguageTag'
              options={this.props.AlldetailsReducers.AllDetails.Languages}
            />

            <SelectOne
              placeholder={getTranslatation('phoneCounryID', 'USERS')}
              label={getTranslatation('phoneCounryID', 'USERS')}
              helperText={getTranslatation('phoneCounryID_textHelper', 'USERS')}
              xs={12}
              sm={6}
              lg={4}
              value={this.state.phoneCounryID}
              onChange={this.setVal}
              name='phoneCounryID'
              id='phoneCounryID'
              options={CountryPhoneCodes}
            />

            <InputOne
              mask='999 999 99 99'
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='phones_text'
              label={getTranslatation('phones_text', 'USERS')}
              name='phones_text'
              value={this.state.phones_text}
              onChange={this.setVal}
              disabled={this.state.phoneCounryID == ''}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('phones_textHelper', 'USERS')}
            />

            <SelectOne
              placeholder={getTranslatation('isActive', 'USERS')}
              label={getTranslatation('isActive', 'USERS')}
              helperText={getTranslatation('isActive_textHelper', 'USERS')}
              xs={12}
              sm={6}
              lg={2}
              value={this.state.isActive}
              onChange={this.setVal}
              name='isActive'
              id='isActive'
              options={[
                { value: true, text: 'AKTİF' },
                { value: false, text: 'PASİF' }
              ]}
            />




            <GridBlock
              title={'adres'}
              container={true}
              align={'flex-start'}
              xs={12}
              sm={12}
              lg={12}
            >
              <SelectOne
                placeholder={getTranslatation('adresCountryID_HOME', 'USERS')}
                label={getTranslatation('adresCountryID_HOME', 'USERS')}
                // helperText={getTranslatation('Optional')}
                xs={12}
                sm={12}
                lg={12}
                value={this.state.adresCountryID_HOME}
                onChange={this.setVal}
                name='adresCountryID_HOME'
                id='adresCountryID_HOME'
                options={this.props.AlldetailsReducers.AllDetails.CountryNames}
                // disabled={true}
                helperText={getTranslatation('adresCountryID_HOMEHelper', 'USERS')}
              />

              {this.props.AlldetailsReducers.AllDetails.States.filter(
                aa => aa.countryID == this.state.adresCountryID_HOME
              ).length > 0 && (
                  <SelectOne
                    placeholder={getTranslatation('adresStateID_HOME', 'USERS')}
                    label={getTranslatation('adresStateID_HOME', 'USERS')}
                    // helperText={getTranslatation('Optional')}
                    xs={12}
                    sm={12}
                    lg={12}
                    value={this.state.adresStateID_HOME}
                    onChange={this.setVal}
                    name='adresStateID_HOME'
                    id='adresStateID_HOME'
                    options={this.props.AlldetailsReducers.AllDetails.States.filter(
                      aa => aa.countryID == this.state.adresCountryID_HOME
                    )}
                    disabled={this.state.adresCountryID_HOME == ''}
                    helperText={getTranslatation('adresStateID_HOMEHelper', 'USERS')}
                  />
                )}

              <SelectOne
                placeholder={getTranslatation('adresCityID_HOME', 'USERS')}
                label={getTranslatation('adresCityID_HOME', 'USERS')}
                // helperText={getTranslatation('Optional')}
                xs={12}
                sm={12}
                lg={
                  this.props.AlldetailsReducers.AllDetails.States.filter(
                    aa => aa.countryID == this.state.adresCountryID_HOME
                  ).length > 0
                    ? 12
                    : 12
                }
                value={this.state.adresCityID_HOME}
                onChange={this.setVal}
                name='adresCityID_HOME'
                id='adresCityID_HOME'
                options={this.props.AlldetailsReducers.AllDetails.Cities.filter(
                  aa => aa.countryID == this.state.adresCountryID_HOME
                ).filter(bb => {
                  if (
                    this.props.AlldetailsReducers.AllDetails.States.filter(
                      aa => aa.countryID == this.state.adresCountryID_HOME
                    ).length > 0
                  ) {
                    return bb.stateID == this.state.adresStateID_HOME
                  } else {
                    return true
                  }
                })}
                disabled={
                  this.props.AlldetailsReducers.AllDetails.States.filter(
                    aa => aa.countryID == this.state.adresCountryID_HOME
                  ).length > 0
                    ? this.state.adresStateID_HOME == ''
                    : this.state.adresCountryID_HOME == ''
                }
                helperText={getTranslatation('adresCityID_HOMEHelper', 'USERS')}
              />

              <InputOne
                multiline={true}
                minRows={2}
                color={'success'}
                variant='filled'
                margin='none'
                required
                fullWidth
                xs={12}
                sm={12}
                lg={12}
                id='adress_text_HOME'
                label={getTranslatation('adress_text_HOME', 'USERS')}
                name='adress_text_HOME'
                value={this.state.adress_text_HOME}
                onChange={this.setVal}
                disabled={this.state.adresCityID_HOME == ''}
                // error={this.state.mailOrPhone_ERROR.length > 0}
                helperText={getTranslatation('adress_text_HOMEHelper', 'USERS')}
              />
            </GridBlock>
          </GridBlock>



          <GridBlock
            title='Kullanıcı Programları'
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
            <Programlar 
            programOptions={this.state.programOptions} 
            programlar={this.state.programlar} 
            fakeThis={this} 
            fakeProps={this.props} 
            fakeState={this.state} 
            />
          </GridBlock>
        </GridBlock>

      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(UsersActions.GetOne, dispatch),
      Create: bindActionCreators(UsersActions.Create, dispatch),
      Update: bindActionCreators(UsersActions.Update, dispatch),
      GetUserPrograms: bindActionCreators(UsersActions.GetUserPrograms, dispatch),

      AssignProgramToUser: bindActionCreators(UsersActions.AssignProgramToUser, dispatch),
      UnAssignProgramToUser: bindActionCreators(UsersActions.UnAssignProgramToUser, dispatch),

      GetAllPrograms: bindActionCreators(ProgramActions.GetAll, dispatch),





      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersForm)
)
