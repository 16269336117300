import { createStore, applyMiddleware } from 'redux'
import rootreducer from './store'
import thunk from 'redux-thunk'

function configureStore () {
  return createStore(rootreducer, applyMiddleware(thunk))
}

const Store = configureStore();
export { Store };
