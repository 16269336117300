import React, { Component } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'

export default class TopMenu extends Component {
  render () {
    return (
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            ...
          </Typography>

          <IconButton
            // sx={{backgroundColor:'gray'}}
            size='large'
            aria-label='logout'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={() => {}}
            color='inherit'
          >
            <PowerSettingsNewIcon sx={{ fontSize: 33 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}
