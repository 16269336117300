import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getTranslatation } from '../../../../locales/translate'
import { getReduceState } from '../../../funcs/BASES/getStateOfRed'

import { TableOne } from '../../../lib/table'
import { ProgramCols } from '../../TableCols/Users'
import { FaSearch, FaPlus } from 'react-icons/fa'
import { InputOne } from '../../../lib/input'
import { IconButton } from '../../../lib/button'
import { GridBlock } from '../../../lib/grid'

import { confirmAlert } from 'react-confirm-alert'
import { AddModal } from './modal'

export default class Programlar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstSyncIsComplated: false,
      searchWord: '',
      datas: [],

      modalIsOpen: false
    }
  }

  componentDidUpdate(prevProps, PrevState) {
    if (
      this.props?.programlar != prevProps?.programlar &&
      this.state.firstSyncIsComplated == false
    ) {
      this.setState({
        firstSyncIsComplated: true,
        datas: this.props?.programlar.map(aa => {
          if (aa?.uuid == null) {
            aa['uuid'] = uuidv4()
            return aa
          } else {
            return aa
          }
        })
      })
    }

    if (this.state?.datas != PrevState?.datas) {
      // datalarda degisiklik varsa
      this.props.fakeThis.setState({ programlar: this.state?.datas }) // datasla programlar sync et
      console.log('programlar sync edildi')
    }
  }

  controlledTableData = () => {
    return this.state.datas
  }

  //********************//
  getUpdatedUserPrograms = () => {
    this.props.fakeProps.actions.GetUserPrograms(
      this.props.fakeState.users_id,
      () => { },
      () => { },
      data => {
        this.setState({
          datas: data.map(aa => {
            if (aa?.uuid == null) {
              aa['uuid'] = uuidv4()
              return aa
            } else {
              return aa
            }
          })
        },
          () => { }
        )
      })
  }

  unAssign = users_has_program_id => {
    confirmAlert({
      title: 'VERI KAYBI UYARISI',
      message:
        'Devam etmek için, TAMAM butonuna, Vazgeçmek için VAZGEÇ butonuna basınız.',
      buttons: [
        {
          label: 'TAMAM',
          onClick: () => {
            this.props.fakeProps.actions.UnAssignProgramToUser(
              { users_has_program_id },
              () => { },
              () => { },
              data => {
                this.getUpdatedUserPrograms()
              },
              () => { }
            )
          }
        },
        {
          label: 'VAZGEÇ'
          // onClick: () => alert('Click No')
        }
      ]
    })
  }

  assign = ({ users_id, program_id, startTime, challangeId }) => {
    this.props.fakeProps.actions.AssignProgramToUser(
      {
        users_id,
        program_id,
        startTime,
        challenge_ID: challangeId
      },
      () => { },
      () => { },
      data => {
        this.setState({ modalIsOpen: false }, () => {
          setTimeout(() => {
            this.getUpdatedUserPrograms()
          }, 1000)
        })
      },
      () => { }
    )
  }

  render() {
    const AlldetailsReducers = getReduceState('UsersReducers')
    return (
      <GridBlock style={{ padding: 10 }} >
        <AddModal
          open={this.state.modalIsOpen}
          fakeThis={this}
          fakeState={this.state}
          fakeProps={this.props}
          programOptions={this.props.programOptions}
          users_id={this.props.fakeState.users_id}
        />

        <TableOne
          //   title={getTranslatation('tableTitle','ACTIONS')}
          responsive={true}
          subHeader={true}
          columns={ProgramCols(this.unAssign)}
          data={this.controlledTableData()}
          subHeaderComponent={
            <GridBlock
              container
              justify={'space-between'}
              align={'space-between'}
              style={{ padding: 10 }}
            >
              <IconButton
                icon={<FaPlus />}
                onClick={() => {
                  this.setState({ modalIsOpen: true })
                }}
              />
            </GridBlock>
          }
        />
      </GridBlock >
    )
  }
}
