import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'

import endPoints from '../../components/Consts/endPoints.json'
import { Actions, ActionsForm } from '../../views/Actions'

export default class ActionsRoute extends Component {
  render () {
    var releatedRoutes = endPoints.find(aa => aa.name == 'Actions')?.routes
    return (
      <Routes>
        <Route path={releatedRoutes?.get} exact element={<Actions />} />
        <Route path={releatedRoutes?.create} exact element={<ActionsForm />} />
        <Route path={releatedRoutes?.update} exact element={<ActionsForm />} />
        <Route path={releatedRoutes?.delete} exact element={<ActionsForm />} />
      </Routes>
    )
  }
}
