import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import notifyTargets from '../../components/Consts/notifyTargets.json'
import notificationTypes from '../../components/Consts/notificationTypes.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaEnvelope } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'

import * as DegisimlerActions from '../../redux/actions/Degisimler'
import * as AlldetailsActions from '../../redux/actions/Alldetails'
import UploadOne from '../../components/lib/upload/UploadOne'



var curr = new Date();
curr.setDate(curr.getDate() + 1);
var date = curr.toISOString().substring(0, 10);

var curr2 = new Date();
curr2.setDate(curr2.getDate() + 30);
var date2 = curr2.toISOString().substring(0, 10);

// ** 1- Aktif Bir Programi olan kullanıcılar
// ** 2- Tüm Kullanıcılar
// ** 3- Hiç satın alım yapmamış kullanıcılar
// ** 4- Aktif Bir Programi olmayan fakat herhangi bir programı daha oncee satın almıs olan kullanıcılar
//************************************************************** */
// ** 5- aynı gun antrenmanı olanlar
// ** 6- aynı gun antrenmanını tamamlamayanlar
// ** 7- su akdar gun gecmiste dukadar antrenman yapmayanlar
// ** 8- su akdar gun gecmiste dukadar antrenman yapanlara
class ChangesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {

      onProcess: false,
      activeSection: 'form', // files

      id: '',
      fileOne: {},
      fileTwo: {},
      description: '',
      onPublish: 0,
      canPublish: 0,
      degisimlerim_order: 0,

    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Changes')?.routes;
  }

  componentDidMount() {

    try {
      var id = this.props.router.location.state.id
      this.setState({ id: id }, () => {
        this.props.actions.GetOne(
          this.state.id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {

            this.setPageForGETID(data)
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    console.log(data)
    this.setState({
      fileOne: data?.fileOne,
      fileTwo: data?.fileTwo,

      canPublish: data?.canPublish,
      description: data?.description,
      onPublish: data?.onPublish,
      degisimlerim_id: data?.degisimlerim_id,
      degisimlerim_order: data?.degisimlerim_order,
    })
  }

  submit = () => {
    this.props.actions.Update(
      this.state,
      () => {
        this.setState({ onProcess: true })
      },
      () => {
        setTimeout(() => {
          this.setState({ onProcess: false })
        }, 500)
      },
      () => {
        this.props.router.navigate(this.releatedRoutes.get)
      },
      () => { }
    )
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {

    return (
      <FormLayout
        naviIcon={<FaEnvelope size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={null}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >





        <GridBlock
          title={endPoints.find(aa => aa.name == 'Changes')?.menutext}
          container={true}
          xs={12}
          sm={12}
          lg={12}
        >

          <Grid item xs={12} sm={12} lg={6} style={{ padding: 10 }}>
            <img
              onClick={() => {
                window.open(process.env.REACT_APP_STORAGE_URL + 'Storage/' + (this.state.fileOne?.uuid))
              }}
              src={
                process.env.REACT_APP_STORAGE_URL + 'Storage/' + (this.state.fileOne?.uuid)
              }
              style={{ width: '100%', height: 400, objectFit: 'cover' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} style={{ padding: 10 }}>
            <img

              onClick={() => {
                window.open(process.env.REACT_APP_STORAGE_URL + 'Storage/' + (this.state.fileTwo?.uuid))
              }}
              src={
                process.env.REACT_APP_STORAGE_URL + 'Storage/' + (this.state.fileTwo?.uuid)
              }
              style={{ width: '100%', height: 400, objectFit: 'cover' }}
            />
          </Grid>


          <InputOne
            multiline={true}
            minRows={8}
            color={'success'}
            variant='filled'
            margin='none'
            required
            fullWidth
            xs={12}
            sm={12}
            lg={12}
            id='description'
            label={getTranslatation('description', 'CHANGES')}
            name='description'
            value={this.state.description}
            onChange={this.setVal}
            // error={this.state.mailOrPhone_ERROR.length > 0}
            helperText={getTranslatation('descriptionHelper', 'CHANGES')}
          />


          <InputOne
            multiline={false}
            minRows={1}
            color={'success'}
            variant='filled'
            margin='none'
            required
            fullWidth
            xs={12}
            sm={12}
            lg={2}
            id='degisimlerim_order'
            label={getTranslatation('degisimlerim_order', 'CHANGES')}
            name='degisimlerim_order'
            value={this.state.degisimlerim_order}
            onChange={this.setVal}
            // error={this.state.mailOrPhone_ERROR.length > 0}
            helperText={getTranslatation('degisimlerim_orderHelper', 'CHANGES')}
          />

          <SelectOne
            placeholder={getTranslatation('canPublish', 'CHANGES')}
            label={getTranslatation('canPublish', 'CHANGES')}
            helperText={getTranslatation('canPublishHelper', 'CHANGES')}
            xs={12}
            sm={12}
            lg={5}
            value={this.state.canPublish}
            onChange={this.setVal}
            name='canPublish'
            id='canPublish'
            options={[
              { value: 1, text: 'Yayın Onayı Var' },
              { value: 0, text: 'Yayın Onayı Yok' },
            ]}
            disabled={true}
          />

          <SelectOne
            // disabled={this.state.canPublish == 0}
            placeholder={getTranslatation('onPublish', 'CHANGES')}
            label={getTranslatation('onPublish', 'CHANGES')}
            helperText={getTranslatation('onPublishHelper', 'CHANGES')}
            xs={12}
            sm={12}
            lg={5}
            value={this.state.onPublish}
            onChange={this.setVal}
            name='onPublish'
            id='onPublish'
            options={[
              { value: 1, text: 'Yayında' },
              { value: 0, text: 'Yayında Değil' },
            ]}
          />

        </GridBlock>



      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(DegisimlerActions.GetOne, dispatch),
      Update: bindActionCreators(DegisimlerActions.Update, dispatch),
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangesForm)
)
