import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getTranslatation } from '../../../../locales/translate'
import { getReduceState } from '../../../funcs/BASES/getStateOfRed'

import { TableOne } from '../../../lib/table'
import { Cols } from '../../TableCols/Workout/AddTableCols'
import { FaSearch, FaPlus } from 'react-icons/fa'
import { InputOne } from '../../../lib/input'
import { IconButton } from '../../../lib/button'
import { GridBlock } from '../../../lib/grid'

import { AddModal } from './modal'


export default class Exercises extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstSyncIsComplated: false,
      searchWord: '',
      datas: [],

      modalIsOpen: false
    }
  }

  componentDidUpdate (prevProps, PrevState) {
    if (this.props?.egzersizler != prevProps?.egzersizler && this.state.firstSyncIsComplated == false) {
      this.setState({
        firstSyncIsComplated: true,
        datas: this.props?.egzersizler.map(aa => {
          if(aa?.uuid == null){
            aa['uuid'] = uuidv4()
            return aa
          }else{
            return aa
          }
        })
      })
    }

    if (this.state?.datas != PrevState?.datas) {
      // datalarda degisiklik varsa
      this.props.fakeThis.setState({ egzersizler: this.state?.datas }) // datasla egzersizler sync et
      console.log('egzersizler sync edildi')
    }
  }

  controlledTableData = () => {
    return this.state.datas
  }

  //********************//    
  delete = uuid => {
    this.setState({
      datas: this.state.datas.filter(aa => aa.uuid != uuid)
    })
  }

  render () {

    const AlldetailsReducers = getReduceState('AlldetailsReducers')
    return (
      <GridBlock style={{ padding: 10 }}>
        <AddModal
          open={this.state.modalIsOpen}
          fakeThis={this}
          fakeState={this.state}
          fakeProps={this.props}
        />

        <TableOne
          //   title={getTranslatation('tableTitle','ACTIONS')}
          responsive={true}
          subHeader={true}
          columns={Cols(this.delete,AlldetailsReducers?.AllDetails?.Egzersizler)}
          data={this.controlledTableData()}
          subHeaderComponent={
            <GridBlock
              container
              justify={'space-between'}
              align={'space-between'}
              style={{ padding: 10 }}
            >
              <IconButton
                icon={<FaPlus />}
                onClick={() => {
                  this.setState({ modalIsOpen: true })
                }}
              />

              <InputOne
                color={'success'}
                variant='filled'
                margin='none'
                required
                fullWidth
                xs={10}
                sm={6}
                lg={4}
                id='searchWord'
                label={getTranslatation('aramayap')}
                placeHolder={'Arama Yap'}
                name='searchWord'
                value={this.state.searchWord}
                onChange={this.setVal}
                // error={this.state.password_ERROR.length > 0}
                helperText={getTranslatation('aramayap')}
                endAdornment={<FaSearch />}
              />
            </GridBlock>
          }
        />
      </GridBlock>
    )
  }
}
