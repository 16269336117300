import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@material-ui/core/Grid'

export default class buttonOne extends Component {
  render () {
    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} lg={this.props.lg}>
        <Button
          style={this.props.style}
          type={this.props.type}
          fullWidth
          variant={this.props.variant}
          color={this.props.color}
          className={this.props.className}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
        >
          {this.props.text}
        </Button>
      </Grid>
    )
  }
}

buttonOne.defaultProps = {
  xs: 12,
  sm: 12,
  lg: 12,
  type: 'text'
}

buttonOne.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string
}
