var nameSpace = '___Notification'


export const GETALL_SUCCESS = "GETALL_SUCCESS" + nameSpace;
export const GETONE_SUCCESS = "GETONE_SUCCESS" + nameSpace;
export const CREATE_SUCCESS = "CREATE_SUCCESS" + nameSpace;
export const UPDATE_SUCCESS = "UPDATE_SUCCESS" + nameSpace;
export const DELETE_SUCCESS = "DELETE_SUCCESS" + nameSpace;
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS" + nameSpace;


