import { Request } from '../../components/funcs/BASES/Request'
import { getReduceState } from '../../components/funcs/BASES/getStateOfRed'




import { GET_ONE_SUCCESS, UPLOAD_SUCCESS, DELETE_SUCCESS } from '../types/Storage';

//******* SUCESSS **//
export const getOneSuccess = data => {
  return {
    type: GET_ONE_SUCCESS,
    payload: data
  }
}

export const uploadSuccess = data => {
  return {
    type: UPLOAD_SUCCESS,
    payload: data
  }
}

export const deleteSuccess = data => {
  return {
    type: DELETE_SUCCESS,
    payload: data
  }
}

// getAllPayments
export const GetOne = (Data, before, after, onSuccess, onError) => dispatch => {
  Request(
    'GET',
    '/Storage',
    Data,
    data => {
      dispatch(getOneSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false, // show success messages,
    { type: 'storage' } // forStorage
  )
}

// getAllPayments
export const Delete = (Data, before, after, onSuccess, onError) => dispatch => {
  Request(
    'DELETE',
    '/Storage',
    Data,
    data => {
      dispatch(deleteSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false, // show success messages,
    { type: 'storage' } // forStorage
  )
}

export const Upload = (Data, before, after, onSuccess, onError) => dispatch => {
  var formdata = new FormData()
  try {
    Data.files.map(file => {
      console.log(file)
      formdata.append('file', file, file.name)
    })
  } catch (err) {}

  formdata.append('cascadeID', Data?.cascadeID)
  formdata.append('formID', Data?.formID)
  formdata.append('langID', Data?.langID)
  formdata.append('isAws', Data?.isAws)

  var config = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
    headers: {'Authorization': 'Bearer ' + String(getReduceState('AuthReducers').jwtToken)}
    // headers: { 'Content-Type': 'multipart/form-data; boundary=----somefixedboundary' }
  }


  Request(
    'POST',
    '/Storage',
    Data,
    data => {
      dispatch(uploadSuccess(data))
    }, //success reduce
    () => {}, //error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    true, //show success messages,
    { type: 'storage', formdata, config } //forStorage
  )
}
