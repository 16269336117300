import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'
import Rating from '@mui/material/Rating';

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Ad Soyad',
      selector: 'fullname',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.fullname}</div>
    },
    {
      name: 'Puanlama',
      selector: 'degerlendirme_point',
      sortable: true,
      cell: row => <div>  <Rating name="read-only" value={row.degerlendirme_point} readOnly /> </div>
    },

    {
      name: 'Sıralama',
      selector: 'degerlendirme_order',
      sortable: true,
      cell: row => <div>  {row.degerlendirme_order} </div>
    },

    
    
    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.degerlendimeler_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.degerlendimeler_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
