import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaShapes } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'
import UploadOne from '../../components/lib/upload/UploadOne'

import { Ogunler } from '../../components/features/Nutrition'


import * as BeslenmeActions from '../../redux/actions/Beslenme'
import * as AlldetailsActions from '../../redux/actions/Alldetails'

class NutritionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {

      onProcess: false,
      activeSection: 'form', // files

      beslenme_id: '',

      title: '',
      description: '',

      nDays: 0,
      ogunler: [],
      beslenmeCategories: []
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Nutrition')?.routes
    this.ref_fileArea = React.createRef()
  }

  componentDidMount() {
    this.props.actions.GetAllDetails(null, () => { }, () => { }, () => { }, () => { })
    try {
      var id = this.props.router.location.state.id
      this.setState({ beslenme_id: id }, () => {
        this.props.actions.GetOne(
          this.state.beslenme_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    this.setState({
      title: data?.title,
      description: data?.description,

      nDays: data?.nDays,
      ogunler: data?.ogunler,
      beslenmeCategories: data?.categories,
    }, () => {
      this.ref_fileArea.current.state.files = data?.medias != null ? data?.medias : []
    })
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <FormLayout
        naviIcon={<FaShapes size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.beslenme_id != null && this.state.beslenme_id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >

        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>
          <UploadOne
            ref={this.ref_fileArea}
            innerRef={this.ref_fileArea}
            cascadeID={this.state.beslenme_id}
            showDropAreaWhenFull={true}
            formID={7}
            allowedLength={1}
            xs={12}
            sm={12}
            lg={4}
            _xs={12}
            _sm={12}
            _lg={12}
            _mainContainer
            acceptFiles={'image/jpeg, image/png, image/jpg'}
            dropLabel={
              <div>
                Beslenme resmi yükleyiniz <br /> en fazla 1 Adet{' '}
              </div>
            }
          />

        </GridBlock>

        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>

          <GridBlock title={endPoints.find(aa => aa.name == 'Nutrition')?.menutext} container={true} xs={12} sm={12} lg={12}>
            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='title'
              label={getTranslatation('title', 'NUTRITION')}
              name='title'
              value={this.state.title}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('titleHelper', 'NUTRITION')}
            />


            <InputOne
              multiline={true}
              minRows={8}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='description'
              label={getTranslatation('description', 'NUTRITION')}
              name='description'
              value={this.state.description}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('descriptionHelper', 'NUTRITION')}
            />


            <SelectOne
              multiple={true}
              placeholder={getTranslatation('beslenmeCategories', 'NUTRITION')}
              label={getTranslatation('beslenmeCategories', 'NUTRITION')}
              helperText={getTranslatation('beslenmeCategoriesHelper', 'NUTRITION')}
              xs={12}
              sm={12}
              lg={12}
              value={this.state.beslenmeCategories}
              onChange={this.setVal}
              name='beslenmeCategories'
              id='beslenmeCategories'
              options={this.props.AlldetailsReducers?.AllDetails?.BeslenmeCategory}
            />




          </GridBlock>


          <GridBlock
            title='Eklenmiş Öğünler'
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
            <Ogunler ogunler={this.state.ogunler} fakeThis={this} />
          </GridBlock>

        </GridBlock>


      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(BeslenmeActions.GetOne, dispatch),
      Create: bindActionCreators(BeslenmeActions.Create, dispatch),
      Update: bindActionCreators(BeslenmeActions.Update, dispatch),

      GetAllDetails: bindActionCreators(AlldetailsActions.GetAllDetails, dispatch)

      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NutritionForm)
)
