import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { SelectOne } from '../../../../lib/select'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'

import { getReduceState } from '../../../../funcs/BASES/getStateOfRed'
import { showMultipleMessages } from '../../../../funcs/BASES/showErrorMultiMessages'

export default class AddModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      antrenman_antrenman_id: '',
      orderDay: '',
      uuid: ''
    }
  }

  submit = () => {


    if(this.props.fakeState.datas.find(aa=> aa.orderDay == this.state.orderDay)?.antrenman_antrenman_id != null){
      showMultipleMessages("Eklemis oldugunuz sıra gününe bir antrenman var.Lütfen başka bir gün belirleyiniz.",'warning')
    }else if(this.state.antrenman_antrenman_id == ''){
      showMultipleMessages("Eklemek için mutlaka bir antrenman seçilmiş olmalıdır.",'warning')
    }else if(this.state.orderDay == ''){
      showMultipleMessages("Eklemek için mutlaka bir gün seçilmiş olmalıdır.",'warning')
    }else{
      this.setState(
        {
          uuid: uuidv4()
        },
        () => {
          this.props.fakeThis.setState(
            {
              datas: [...this.props.fakeState.datas, this.state],
              modalIsOpen: false
            },
            () => {
              this.setState({
                antrenman_antrenman_id: '',
                orderDay: '',
                uuid: ''
              })
            }
          )
        }
      )
    }

  
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const AlldetailsReducers = getReduceState('AlldetailsReducers')
    return (
      <ModalOne
        open={this.props.open}
        bgDropColor={'rgba(128, 2, 255, 0.2)'}
        fullScreen={true}
      >
        <FormLayout
          naviIcon={<FaWpforms size={30} color={'white'} />}
          recordIcon={<FaSave size={30} color={'white'} />}
          recordAction={this.submit}
          // filesIcon={<FaFolder size={30} color={'white'} />}
          activeBack={() => {
            this.props.fakeThis.setState({ modalIsOpen: false })
          }}
          loading={false}
        >
          <GridBlock
            title={getTranslatation('egzersizehareketbağlayın', 'PROGRAMS')}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
            <InputOne
              type={'number'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='orderDay'
              label={getTranslatation('orderDay', 'PROGRAMS')}
              name='orderDay'
              value={this.state.orderDay}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('orderDayHelper', 'PROGRAMS')}
            />


            <SelectOne
              placeholder={getTranslatation('antrenman_antrenman_id', 'PROGRAMS')}
              label={getTranslatation('antrenman_antrenman_id', 'PROGRAMS')}
              helperText={getTranslatation('antrenman_antrenman_idHelper', 'PROGRAMS')}
              xs={12}
              sm={6}
              lg={6}
              value={this.state.antrenman_antrenman_id}
              onChange={this.setVal}
              name='antrenman_antrenman_id'
              id='antrenman_antrenman_id'
              options={AlldetailsReducers?.AllDetails?.Antrenmanlar}
            />
          </GridBlock>
        </FormLayout>
      </ModalOne>
    )
  }
}
