import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'
import Rating from '@mui/material/Rating';
import Moment from 'react-moment';

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Program Adı',
      selector: 'productName',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.productName}</div>
    },
    {
      name: 'Challange Adı',
      selector: 'challangeName',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.challangeName}</div>
    },
    {
      name: 'Kullanıcı Adı',
      selector: 'userName',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.userName}</div>
    },
    {
      name: 'Ödeme',
      selector: 'price',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.price}</div>
    },
    {
      name: 'Zaman',
      selector: 'createAt',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>  <Moment format="DD/MM/YYYY HH:mm" locale="tr">{row?.createAt}</Moment>
      </div>
    },

    // {
    //   name: '',
    //   selector: 'update',
    //   sortable: true,
    //   width: '90px',
    //   cell: row => (
    //     <IconButton
    //       icon={<FaEdit size={30} />}
    //       onClick={() => onRowClickUpdate(row.degerlendimeler_id)}
    //     // disabled={row.users_id < 1000 ? true : false}
    //     />
    //   )
    // },

    // {
    //   name: '',
    //   selector: 'delete',
    //   sortable: true,
    //   width: '90px',
    //   cell: row => (
    //     <IconButton
    //       icon={<FaEraser size={30} />}
    //       onClick={() => onRowClickedDelete(row.degerlendimeler_id)}
    //     // disabled={row.users_id < 1000 ? true : false}
    //     />
    //   )
    // }
  ]
}
