import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaShapes } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'
import UploadOne from '../../components/lib/upload/UploadOne'
import ReactCardFlip from 'react-card-flip'

import { StepDesc } from '../../components/features/Actions'

import * as HareketlerActions from '../../redux/actions/Hareketler'

class ActionsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onProcess: false,
      activeSection: 'form', // files

      hareketler_id: '',

      name: '',
      description: '',
      timeSecond: '',
      calory: '',
      isStatic: false,
      isDeleted: false,
      stepDesc: []
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Actions')?.routes
    this.ref_fileArea1 = React.createRef()
    this.ref_fileArea2 = React.createRef()
    this.ref_fileArea3 = React.createRef()
  }

  componentDidMount() {
    try {
      var id = this.props.router.location.state.id
      this.setState({ hareketler_id: id }, () => {
        this.props.actions.GetOne(
          this.state.hareketler_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    this.setState(
      {
        name: data?.name,
        description: data?.description,
        timeSecond: data?.timeSecond,
        calory: data?.calory,
        isStatic: data?.isStatic,
        isDeleted: data?.isDeleted,
        stepDesc: data?.stepDesc
      },
      () => {
        this.ref_fileArea1.current.state.files = data?.medias != null ? data?.medias : [];
        this.ref_fileArea2.current.state.files = data?.vdBaslangic != null ? data?.vdBaslangic : [];
        this.ref_fileArea3.current.state.files = data?.video != null ? data?.video : [];
      }
    )
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <FormLayout
        naviIcon={<FaShapes size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.hareketler_id != null && this.state.hareketler_id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >




        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>

          <GridBlock
            title='Doyalar' container={true} xs={12} sm={12} lg={12}>
            <UploadOne
              ref={this.ref_fileArea2}
              innerRef={this.ref_fileArea2}
              cascadeID={this.state.hareketler_id}
              showDropAreaWhenFull={true}
              formID={9}
              allowedLength={1}
              xs={12}
              sm={12}
              lg={4}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'image/jpeg, image/png, image/jpg'}
              dropLabel={
                <div>
                  Hareket Başlangıç resmi yükleyiniz <br /> en fazla 1 Adet{' '}
                </div>
              }
            />


            <UploadOne
              ref={this.ref_fileArea3}
              innerRef={this.ref_fileArea3}
              cascadeID={this.state.hareketler_id}
              showDropAreaWhenFull={true}
              formID={10} // userprofile image
              allowedLength={1}
              xs={12}
              sm={12}
              lg={4}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'video/mp4'}
              dropLabel={
                <div>
                  Hareket videosu yükleyiniz <br /> en fazla 1 Adet{' '}
                </div>
              }
            />


            <UploadOne
              ref={this.ref_fileArea1}
              innerRef={this.ref_fileArea1}
              cascadeID={this.state.hareketler_id}
              showDropAreaWhenFull={true}
              formID={3} // userprofile image
              allowedLength={5}
              xs={12}
              sm={12}
              lg={4}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'image/jpeg, image/png, image/jpg, video/mp4'}
              dropLabel={
                <div>
                  Hareket bilgi resmi yükleyiniz <br /> en fazla 5 Adet{' '}
                </div>
              }
            />
          </GridBlock>
        </GridBlock>




        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>
          <GridBlock title='Hareketler' container={true} xs={12} sm={12} lg={12}>
            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='name'
              label={getTranslatation('name', 'ACTIONS')}
              name='name'
              value={this.state.name}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('nameHelper', 'ACTIONS')}
            />

            <InputOne
              type={'number'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='timeSecond'
              label={getTranslatation('timeSecond', 'ACTIONS')}
              name='timeSecond'
              value={this.state.timeSecond}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('timeSecondHelper', 'ACTIONS')}
            />

            <InputOne
              type={'number'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={3}
              id='calory'
              label={getTranslatation('calory', 'ACTIONS')}
              name='calory'
              value={this.state.calory}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('caloryHelper', 'ACTIONS')}
            />

            <SelectOne
              placeholder={getTranslatation('isStatic', 'USERS')}
              label={getTranslatation('isStatic', 'USERS')}
              helperText={getTranslatation('isStatic_textHelper', 'USERS')}
              xs={12}
              sm={6}
              lg={3}
              value={this.state.isStatic}
              onChange={this.setVal}
              name='isStatic'
              id='isStatic'
              options={[
                { value: 1, text: 'STATIC' },
                { value: 0, text: 'NON-STATIC' }
              ]}
            />

            <InputOne
              multiline={true}
              minRows={4}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='description'
              label={getTranslatation('description', 'ACTIONS')}
              name='description'
              value={this.state.description}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('descriptionHelper', 'ACTIONS')}
            />

            <GridBlock
              title='Açıklamalar'
              container={true}
              xs={12}
              sm={12}
              lg={12}
            >
              <StepDesc stepDesc={this.state.stepDesc} fakeThis={this} />
            </GridBlock>
          </GridBlock>
        </GridBlock>

      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(HareketlerActions.GetOne, dispatch),
      Create: bindActionCreators(HareketlerActions.Create, dispatch),
      Update: bindActionCreators(HareketlerActions.Update, dispatch)

      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsForm)
)
