import { Request } from '../../components/funcs/BASES/Request'


import {
  //******* SUCESSS **//
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../types/Auth'

//******* SUCESSS **//
export const LoginSuccess = data => {
  return {
    type: LOGIN_SUCCESS,
    payload: data
  }
}

export const LogoutSuccess = data => {
  return {
    type: LOGOUT_SUCCESS,
    payload: data
  }
}

//*********************************************************************************** */

// LOGIN -- componenet içinden atesliyoruz
export const Login = (Data, before, after, onSuccess, onError) => dispatch => {

  Request(
    'POST',
    '/Login',
    Data,
    (data)=> { dispatch(LoginSuccess(data)) }, //success reduce
    ()=>{}, // error reduce


    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false // show success messages
  )
}

// LOGIN -- componenet içinden atesliyoruz
export const Logout = () => dispatch => {
  localStorage.clear();
  dispatch(LogoutSuccess())
}
