import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'



export const Cols = ( onRowClickedDelete , Antrenmanlar) => {
  return [
 

    {
      name: 'Ad',
      selector: 'name',
      sortable: true,
     
      cell: row => <div style={{ fontSize: 14 }}>{row.name}</div>
    },

    {
      name: 'Açıklama',
      selector: 'description',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.description}</div>
    },

    

    {
      name: 'Başlangıç',
      selector: 'startTime',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.startTime}</div>
    },


    
    {
      name: 'Bitiş',
      selector: 'endTime',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.endTime}</div>
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.uuid)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
