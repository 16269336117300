import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'
import Moment from 'react-moment'

export const Cols = ( onRowClickedDelete) => {
  return [
    {
      name: 'Program Adı',
      selector: 'programName',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.program.programName}</div>
    },
    {
      name: 'Seviye',
      selector: 'fitnessLevelID',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>{row.program.fitnessLevelID}</div>
      )
    },
    {
      name: 'Başlangıç Tarihi',
      selector: 'startTime',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {<Moment date={row.startTime} locale='tr' format='YYYY-MM-DD' />}{' '}
          {/* YYYY-MM-DD HH:mm */}
        </div>
      )
    },
  
    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.users_has_program_id)}
        // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
