import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { SelectOne } from '../../../../lib/select'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'
import { showMultipleMessages } from '../../../../funcs/BASES/showErrorMultiMessages'

import { getReduceState } from '../../../../funcs/BASES/getStateOfRed'

export default class AddModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      soru: '',
      cevap: '',
      uuid: ''
    }
  }

  submit = () => {
    if (this.state.soru == '') {
      showMultipleMessages("Soru boş bırakılamaz.")
    } else if (this.state.cevap == '') {
      showMultipleMessages("Cevap boş bırakılamaz.")
    } else {
      this.setState(
        {
          uuid: uuidv4()
        },
        () => {
          this.props.fakeThis.setState(
            {
              datas: [...this.props.fakeState.datas, this.state],
              modalIsOpen: false
            },
            () => {
              this.setState({
                id: '',
                soru: '',
                cevap: '',
                uuid: ''
              })
            }
          )
        }
      )
    }

  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const AlldetailsReducers = getReduceState('AlldetailsReducers')
    return (
      <ModalOne
        open={this.props.open}
        bgDropColor={'rgba(128, 2, 255, 0.2)'}
        fullScreen={true}
      >
        <FormLayout
          naviIcon={<FaWpforms size={30} color={'white'} />}
          recordIcon={<FaSave size={30} color={'white'} />}
          recordAction={this.submit}
          // filesIcon={<FaFolder size={30} color={'white'} />}
          activeBack={() => {
            this.props.fakeThis.setState({ modalIsOpen: false })
          }}
          loading={false}
        >
          <GridBlock
            title={getTranslatation('antrenmanaegzersizatayın', 'WORKOUT')}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >


            <InputOne
              multiline
              rows={4}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='soru'
              label={getTranslatation('soru', 'QUESTION')}
              placeHolder={getTranslatation('soru', 'QUESTION')}
              name='soru'
              value={this.state.soru}
              onChange={this.setVal}
            />

            <InputOne
              multiline
              rows={4}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={6}
              lg={6}
              id='cevap'
              label={getTranslatation('cevap', 'ANSWER')}
              placeHolder={getTranslatation('cevap', 'ANSWER')}
              name='cevap'
              value={this.state.cevap}
              onChange={this.setVal}
            />

          </GridBlock>
        </FormLayout>
      </ModalOne>
    )
  }
}
