import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@mui/material/Button'

export default class flagButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
        onHovered:false
    }
  }

  render () {
    return (
      <Grid
        item
        xs={this.props.xs}
        sm={this.props.sm}
        lg={this.props.lg}
        style={{ display: 'flex' }}
      >
        <div
          style={{
            width: '100%',
            height: this.props.height 
          }}
          className='imageContainer'
          onMouseOver={() => {
            this.setState({onHovered: true})
          }}
          onMouseLeave={() => {
            this.setState({onHovered: false})
          }}
        >
          <Button
            style={{
              width: '100%',
              height: this.props.height ,
              backgroundImage: ( this.state.onHovered == true ? null : this.props.backgroundImage ),
              fontWeight: '900',
              color: '#FFF',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              fontSize: 18,
              backgroundColor:'black',
              borderRadius: ( this.state.onHovered == true ? 50 : 0 )
            }}
            onClick={this.props.onClick}
          >
            {this.props.text}
          </Button>
        </div>
      </Grid>
    )
  }
}


flagButton.defaultProps = {
  xs: 12,
  sm: 12,
  lg: 12,
  type:'text',
  height:'88vh'
};
