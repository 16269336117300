import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'
import Moment from 'react-moment'

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Ad',
      selector: 'title',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.title}</div>
    },

    {
      name: 'Başlangıç Tarihi',
      selector: 'startDate',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {<Moment date={row.startDate} format='YYYY-MM-DD' />} {/* YYYY-MM-DD HH:mm */}
        </div>
      )
    },

    {
      name: 'Bitiş Tarihi',
      selector: 'endDate',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 14 }}>
          {<Moment date={row.endDate} format='YYYY-MM-DD' />} {/* YYYY-MM-DD HH:mm */}
        </div>
      )
    },

    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
