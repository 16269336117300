import * as authTypes from '../types/Auth'
import AuthInitials from '../initials/Auth'
import jwt_decode from 'jwt-decode'

function getLocalstoragedata () {
  var jwtToken = localStorage.getItem('myJWTisHERE')
  var initialDataLoc = AuthInitials

  console.log(jwtToken,'jwtToken')
  console.log(initialDataLoc,'initialDataLoc')
  if (jwtToken) {
    return {
      ...initialDataLoc,
      isAuthenticated: true,
      jwtDecodedToken: jwt_decode(jwtToken),
      jwtToken: jwtToken,
      CustomID: Math.random()
    }
  } else {
    return AuthInitials
  }
}

export default function authReducer (state = getLocalstoragedata(), action) {
  switch (action.type) {
    //*********************************************** */  SUCCESS
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        jwtDecodedToken: jwt_decode(action.payload),
        jwtToken: action.payload,
        messages: [],
        CustomID: Math.random()
      }

    case authTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        jwtDecodedToken: {},
        jwtToken: '',

        messages: [],
        CustomID: Math.random()
      }

    default:
      return state
  }
}
