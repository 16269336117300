import React, { Component } from 'react'
import { FaWpforms, FaSave, FaFolder } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'

import { ModalOne } from '../../../../lib/modal'
import { InputOne } from '../../../../lib/input'
import { SelectOne } from '../../../../lib/select'
import { GridBlock } from '../../../../lib/grid'
import { FormLayout } from '../../../Layouts'
import { getTranslatation } from '../../../../../locales/translate'

import { getReduceState } from '../../../../funcs/BASES/getStateOfRed'
import { showMultipleMessages } from '../../../../funcs/BASES/showErrorMultiMessages'

export default class AddModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      uuid: ''
    }
  }

  submit = () => {


    if (this.props.fakeState.datas.find(aa => aa.id == this.state.id)?.id != null) {
      showMultipleMessages("Eklemiş olduğunuz id değeri sistemde mevcut. Lütfen farklı bir id değeri giriniz.")
    } else {
      this.setState(
        {
          uuid: uuidv4()
        },
        () => {
          this.props.fakeThis.setState(
            {
              datas: [...this.props.fakeState.datas, this.state],
              modalIsOpen: false
            },
            () => {
              this.setState({
                id: '',
                uuid: ''
              })
            }
          )
        }
      )
    }


  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const AlldetailsReducers = getReduceState('AlldetailsReducers')
    return (
      <ModalOne
        open={this.props.open}
        bgDropColor={'rgba(128, 2, 255, 0.2)'}
        fullScreen={true}
      >
        <FormLayout
          naviIcon={<FaWpforms size={30} color={'white'} />}
          recordIcon={<FaSave size={30} color={'white'} />}
          recordAction={this.submit}
          // filesIcon={<FaFolder size={30} color={'white'} />}
          activeBack={() => {
            this.props.fakeThis.setState({ modalIsOpen: false })
          }}
          loading={false}
        >
          <GridBlock
            title={getTranslatation('Degisimler', 'PROGRAMS')}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >

            <SelectOne
              placeholder={getTranslatation('degisimler_selection_placeholder', 'PROGRAMS')}
              label={getTranslatation('degisimler_selection_label', 'PROGRAMS')}
              helperText={getTranslatation('degisimler_selection_helper', 'PROGRAMS')}
              xs={12}
              sm={12}
              lg={12}
              value={this.state.id}
              onChange={this.setVal}
              name='id'
              id='id'
              options={AlldetailsReducers?.AllDetails?.Degisimler.map((item) => {
                return {
                  value: item.value,
                  text: item.text + ' - ' + item.description + ' - ' + (item.canPublish == "1" ? 'Yayınlanabilir' : 'Yayınlanamaz'),
                }
              })}
            />
          </GridBlock>
        </FormLayout>
      </ModalOne>
    )
  }
}
