import * as Icons from 'react-icons/fa'

const DynamicFaIcon = ({ name, color="white", size=30 }) => {
  const IconComponent = Icons[name]

  if (!IconComponent) {
    // Return a default one
    return <Icons.FaBeer style={{size: size}} color={color} />
  }

  return <IconComponent  style={{size: size}} color={color} />
}


export default DynamicFaIcon