import { Request } from '../../components/funcs/BASES/Request'


import {
  GETALL_SUCCESS,
  GETONE_SUCCESS,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  DELETE_SUCCESS,
} from '../types/Egzersizler'


//******* SUCESSS **//


export const GetAllSuccess = data => {
  return {
    type: GETALL_SUCCESS,
    payload: data
  }
}


export const GetOneSuccess = data => {
  return {
    type: GETONE_SUCCESS,
    payload: data
  }
}

export const CreateSuccess = data => {
  return {
    type: CREATE_SUCCESS,
    payload: data
  }
}

export const UpdateSuccess = data => {
  return {
    type: UPDATE_SUCCESS,
    payload: data
  }
}

export const DeleteSuccess = data => {
  return {
    type: DELETE_SUCCESS,
    payload: data
  }
}




// getAllPayments
export const GetAll = (
    Data,
    before,
    after,
    onSuccess,
    onError,
  ) => dispatch => {
    Request(
      'GET',
      '/Auth/Egzersizler',
      Data,
      data => {
        dispatch(GetAllSuccess(data))
      }, //success reduce
      () => {}, // error reduce
  
      before,
      after,
      onSuccess,
      onError,
  
      true, //show error messages
      false, // show success messages
    )
  }


  

// getAllPayments
export const GetOne = (
  Data,
  before,
  after,
  onSuccess,
  onError,
) => dispatch => {
  Request(
    'GET',
    '/Auth/Egzersizler',
    Data,
    data => {
      dispatch(GetOneSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false, // show success messages
  )
}



// getAllPayments
export const Create = (
  Data,
  before,
  after,
  onSuccess,
  onError,
) => dispatch => {
  Request(
    'POST',
    '/Auth/Egzersizler',
    Data,
    data => {
      dispatch(CreateSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false, // show success messages
  )
}





// getAllPayments
export const Update = (
  Data,
  before,
  after,
  onSuccess,
  onError,
) => dispatch => {
  Request(
    'PUT',
    '/Auth/Egzersizler',
    Data,
    data => {
      dispatch(UpdateSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false, // show success messages
  )
}







// getAllPayments
export const Delete = (
  Data,
  before,
  after,
  onSuccess,
  onError,
) => dispatch => {
  Request(
    'DELETE',
    '/Auth/Egzersizler',
    Data,
    data => {
      dispatch(DeleteSuccess(data))
    }, //success reduce
    () => {}, // error reduce

    before,
    after,
    onSuccess,
    onError,

    true, //show error messages
    false, // show success messages
  )
}