import { Store } from '../../../redux/configureStore'


export const getReduceState = ReduceName => {
  if (ReduceName == null) {
    throw new Error('ReduceName can be null')
  } else {
    return Store.getState()[ReduceName]
  }
}
