import React, { Component } from 'react'

import { bounceInRight, bounceInLeft, bounceInDown, fadeIn, fadeInDown } from 'react-animations'
import Radium, { StyleRoot } from 'radium'

const anims = {
  bounceInRight: bounceInRight,
  bounceInLeft: bounceInLeft,
  bounceInDown: bounceInDown,
  fadeIn: fadeIn,
  fadeInDown: fadeInDown
}

function randomIntFromInterval (min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default class animateOne extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  componentDidMount () {
    var timer = randomIntFromInterval(this.props.minDelyaRange, this.props.maxDelyaRange)
    setTimeout(
      () => {
        this.setState({ isVisible: true })
      },
      this.props.delay != null ? this.props.delay : timer
    )
  }

  render () {
    const { isVisible } = this.state
    return (
      <>
        {isVisible && (
          <StyleRoot
            style={[{
              animation: this.props.animation == 'fadeIn' ? 'x 3s' : 'x 1s',
              animationName: Radium.keyframes(
                anims[this.props.animation],
                this.props.animation
              )
            }, this.props.style != null ? this.props.style : {} ]}
          >
            {this.props.children != null && this.props.children}
          </StyleRoot>
        )}
      </>
    )
  }
}

animateOne.defaultProps = {
  animation: 'bounceInRight',
  maxDelyaRange: 1000,
  minDelyaRange: 10
}
