import { IconButton } from '../../../lib/button'
import { FaEraser, FaEdit } from 'react-icons/fa'

export const Cols = (onRowClickUpdate, onRowClickedDelete) => {
  return [
    {
      name: 'Ad',
      selector: 'title',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.title}</div>
    },

    {
      name: 'Gün',
      selector: 'nDays',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.nDays}</div>
    },

    {
      name: 'Öğün',
      selector: 'ogunCount',
      sortable: true,
      cell: row => <div style={{ fontSize: 14 }}>{row.ogunCount}</div>
    },


    {
      name: '',
      selector: 'update',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEdit size={30} />}
          onClick={() => onRowClickUpdate(row.beslenme_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    },

    {
      name: '',
      selector: 'delete',
      sortable: true,
      width: '90px',
      cell: row => (
        <IconButton
          icon={<FaEraser size={30} />}
          onClick={() => onRowClickedDelete(row.beslenme_id)}
          // disabled={row.users_id < 1000 ? true : false}
        />
      )
    }
  ]
}
