import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DataTable, { createTheme } from 'react-data-table-component'

import { AnimateOne } from '../../../components/lib/animation'
import { LottiePlayerOne } from '../../../components/lib/lottie'
import * as animationData from '../../../asserts/lotties/lf30_editor_sznvgp8h.json'
import * as animationData2 from '../../../asserts/lotties/lf30_editor_yjgsuxe8.json'
import * as animationData3 from '../../../asserts/lotties/46864-lovely-cats.json'

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos'
}

const styles = {
  styleOne: {
    textAlign: 'left',
    color: 'white',
    backgroundColor: 'black',
    padding: 10,
    fontWeight: '650',
    borderRadius: 5,
    minHeight: 35
  }
}

export default class TableOne extends Component {
  render () {
    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} lg={this.props.lg}>
        <DataTable
          title={
            this.props.title != null &&
            <AnimateOne delay={0} animation={'fadeIn'} >
              <div style={styles.styleOne}>
                <div style={{ position: 'absolute', right: 10 }}>
             
                    <LottiePlayerOne
                      animationData={animationData}
                      height={40}
                      width={800}
                    />
              
                </div>
                <AnimateOne>
                  {this.props.title}
                </AnimateOne>
                {' '}
              </div>
            </AnimateOne>
          
          }
          columns={this.props.columns}
          data={this.props.data}
          conditionalRowStyles={this.props.conditionalRowStyles}
          responsive={this.props.responsive}
          //selectableRows // add for checkbox selection

          paginationPerPage={this.props.paginationPerPage}
          striped={this.props.striped}
          pagination={this.props.pagination} // paging
          highlightOnHover={this.props.highlightOnHover}
          progressPending={this.props.progressPending}
          subHeader={this.props.subHeader}
          subHeaderComponent={this.props.subHeaderComponent}
          noDataComponent={this.props.noDataComponent}
          progressComponent={this.props.progressComponent}
          paginationComponentOptions={paginationComponentOptions}
        />
      </Grid>
    )
  }
}
