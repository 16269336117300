import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { FormSubHeader } from '../../../components/features/BASES'
import { AnimateOne } from '../../../components/lib/animation'

export default class GridBlock extends Component {
  render () {
    return (
      <Grid
        direction={this.props.direction}
        justify={this.props.justify}
        
        alignItems={this.props.align}
        align={this.props.align}

        spacing={this.props.spacing}
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        container={this.props.container}
        item={this.props.item}
        className={this.props.className}
        style={this.props.container ? Object.assign({padding: 3}, this.props.style) : this.props.style}
        // sx={this.props.style}
        wrap={this.props.wrap}
        display={'flex'}
      >
 

        { this.props.title != null &&   <FormSubHeader title={this.props.title} />}
  
        {this.props.children}
     
        { (this.props.align == 'flex-start' && this.props.justify != 'space-between') &&    <div style={{ height:'100%'}} />}
      
    
      </Grid>
    )
  }
}

GridBlock.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  align: 'flex-start',
  spacing: 0,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  container: false,
  item: false,
  style:{}
}
