import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import notifyTargets from '../../components/Consts/notifyTargets.json'
import notificationTypes from '../../components/Consts/notificationTypes.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaEnvelope } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'
import UploadOne from '../../components/lib/upload/UploadOne'

import * as NotificationActions from '../../redux/actions/Notification'
import * as AlldetailsActions from '../../redux/actions/Alldetails'

// ** 1- Aktif Bir Programi olan kullanıcılar
// ** 2- Tüm Kullanıcılar
// ** 3- Hiç satın alım yapmamış kullanıcılar
// ** 4- Aktif Bir Programi olmayan fakat herhangi bir programı daha oncee satın almıs olan kullanıcılar
//************************************************************** */
// ** 5- aynı gun antrenmanı olanlar
// ** 6- aynı gun antrenmanını tamamlamayanlar
// ** 7- su akdar gun gecmiste dukadar antrenman yapmayanlar
// ** 8- su akdar gun gecmiste dukadar antrenman yapanlara
class NotificationsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onProcess: false,
      activeSection: 'form', // files

      notifications_id: '',

      
      title: '',
      description: '',
      messageType: '',

      type: 2,
      gecmiseGun: 1,
      min: 0,
      max: 1,
      programId: 0
    }
    this.releatedRoutes = endPoints.find(
      aa => aa.name == 'Notifications'
    )?.routes
    this.ref_fileArea = React.createRef()
  }

  componentDidMount() {
    this.props.actions.GetAllDetails(
      null,
      () => { },
      () => { },
      () => { },
      () => { }
    )
    try {
      var id = this.props.router.location.state.id
      this.setState({ notifications_id: id }, () => {
        this.props.actions.GetOne(
          this.state.notifications_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    this.setState({
      title: data?.title,
      description: data?.description,
      messageType: data?.type,
    }, () => {
   
        this.ref_fileArea.current.state.files = data?.files != null ? data?.files : []

    })
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        {
          title: this.state?.title,
          description: this.state?.description,
          messageType: this.state?.messageType,


          type: this.state?.type,
          gecmiseGun: this.state?.gecmiseGun,
          min: this.state?.min,
          max: this.state?.max,
          programId: this.state?.programId,
        },
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        {
          notifications_id: this.state?.notifications_id,
          title: this.state?.title,
          description: this.state?.description,
          messageType: this.state?.messageType,


          type: this.state?.type,
          gecmiseGun: this.state?.gecmiseGun,
          min: this.state?.min,
          max: this.state?.max,
          programId: this.state?.programId,
        },
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    var programOptions = this.props.AlldetailsReducers.AllDetails.Programlar
    programOptions = programOptions.concat([{ text: 'Tümü', value: 0 }])
    return (
      <FormLayout
        naviIcon={<FaEnvelope size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.notifications_id != null && this.state.notifications_id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >



        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>


          <UploadOne
            ref={this.ref_fileArea}
            innerRef={this.ref_fileArea}
            cascadeID={this.state.notifications_id}
            showDropAreaWhenFull={true}
            formID={8} // egzersiz image 1 adet
            allowedLength={1}
            xs={12}
            sm={12}
            lg={12}
            _xs={12}
            _sm={12}
            _lg={12}
            _mainContainer
            acceptFiles={'image/jpeg, image/jpg, image/png, video/mp4, audio/mpeg'}
            dropLabel={
              <div>
                Bildiriminiz için bir media yükleyiniz <br /> en fazla 1 Adet{' '}
              </div>
            }
          />

        </GridBlock>

        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>

          <GridBlock
            title={endPoints.find(aa => aa.name == 'Notifications')?.menutext}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          > 
            <SelectOne
              placeholder={getTranslatation('type', 'NOTIFICATIONS')}
              label={getTranslatation('type', 'NOTIFICATIONS')}
              helperText={getTranslatation('typeHelper', 'NOTIFICATIONS')}
              xs={12}
              sm={12}
              lg={12}
              value={this.state.type}
              onChange={this.setVal}
              name='type'
              id='type'
              options={notifyTargets}
              disabled={this.state.notifications_id != null && this.state.notifications_id != ''}
            />

            {(this.state.type == 7 || this.state.type == 8) && (
              <SelectOne
                placeholder={getTranslatation('gecmiseGun', 'NOTIFICATIONS')}
                label={getTranslatation('gecmiseGun', 'NOTIFICATIONS')}
                helperText={getTranslatation(
                  'gecmiseGunHelper',
                  'NOTIFICATIONS'
                )}
                xs={12}
                sm={12}
                lg={12}
                value={this.state.gecmiseGun}
                onChange={this.setVal}
                name='gecmiseGun'
                id='gecmiseGun'
                options={Array.from(Array(30).keys()).map(aa => {
                  return { value: aa, text: aa + ' Gün ve Şimdinin aralığında' }
                })}
              />
            )}

            {(this.state.type == 7 || this.state.type == 8) && (
              <SelectOne
                placeholder={getTranslatation('min', 'NOTIFICATIONS')}
                label={getTranslatation('min', 'NOTIFICATIONS')}
                helperText={getTranslatation('minHelper', 'NOTIFICATIONS')}
                xs={12}
                sm={12}
                lg={12}
                value={this.state.min}
                onChange={this.setVal}
                name='min'
                id='min'
                options={Array.from(Array(10).keys()).map(aa => {
                  return { value: aa, text: aa + ' Adet Program' }
                })}
              />
            )}



            {(this.state.type == 7 || this.state.type == 8) && (
              <SelectOne
                placeholder={getTranslatation('max', 'NOTIFICATIONS')}
                label={getTranslatation('max', 'NOTIFICATIONS')}
                helperText={getTranslatation('maxHelper', 'NOTIFICATIONS')}
                xs={12}
                sm={12}
                lg={12}
                value={this.state.max}
                onChange={this.setVal}
                name='max'
                id='max'
                options={Array.from(Array(10).keys()).map(aa => {
                  return { value: aa, text: aa + ' Adet Program' }
                })}
              />
            )}


            {(this.state.type == 7 || this.state.type == 8 || this.state.type == 1) && (
              <SelectOne
                placeholder={getTranslatation('programId', 'NOTIFICATIONS')}
                label={getTranslatation('programId', 'NOTIFICATIONS')}
                helperText={getTranslatation('programIdHelper', 'NOTIFICATIONS')}
                xs={12}
                sm={12}
                lg={12}
                value={this.state.programId}
                onChange={this.setVal}
                name='programId'
                id='programId'
                options={programOptions}
              />
            )}



            <SelectOne
              placeholder={getTranslatation('messageType', 'NOTIFICATIONS')}
              label={getTranslatation('messageType', 'NOTIFICATIONS')}
              helperText={getTranslatation('messageTypeHelper', 'NOTIFICATIONS')}
              xs={12}
              sm={12}
              lg={12}
              value={this.state.messageType}
              onChange={this.setVal}
              name='messageType'
              id='messageType'
              options={notificationTypes}
              disabled={this.state.notifications_id != null && this.state.notifications_id != ''}
            />

            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='title'
              label={getTranslatation('title', 'NOTIFICATIONS')}
              name='title'
              value={this.state.title}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('titleHelper', 'NOTIFICATIONS')}
            />

            <InputOne
              multiline={true}
              minRows={8}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='description'
              label={getTranslatation('description', 'NOTIFICATIONS')}
              name='description'
              value={this.state.description}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('descriptionHelper', 'NOTIFICATIONS')}
            />
          </GridBlock>
        </GridBlock>
      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(NotificationActions.GetOne, dispatch),
      Create: bindActionCreators(NotificationActions.Create, dispatch),
      Update: bindActionCreators(NotificationActions.Update, dispatch),

      GetAllDetails: bindActionCreators(
        AlldetailsActions.GetAllDetails,
        dispatch
      )

      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsForm)
)
